import React, { useContext } from 'react';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { TMessageItem } from 'src/api/messages/models';
import { PortalContext } from 'src/context/portal';
import { TaskMessage, TaskMessageInline } from '../Task.styled';
import { prepareOrderLink } from './helpers';
import styled from '@emotion/styled';
import { theme } from 'src/theme';
import { FastingVisible } from 'src/api/assignments/models';

type AddOnTestsProps = {
  task: TMessageItem;
};

const classes = {
  list: {
    listStyleType: 'none',
    padding: '0',
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '0px',
    lineHeight: '24px',
    fontSize: '14px',
  },
};

const Marker = styled.div`
  height: 3px;
  width: 3px;
  background: ${theme.palette.grey[700]};
  border-radius: 50%;
`;

export const AddOnTests: React.FC<AddOnTestsProps> = ({ task }) => {
  const {
    originalOrderNumber,
    tests,
    primaryDiagnosis,
    secondariesDiagnoses,
    orderingProvider,
    billType,
    body,
    fasting,
  } = task;
  const { orderLinkTemplate } = useContext(PortalContext);

  const pathForOrderByNumber = prepareOrderLink(orderLinkTemplate, originalOrderNumber);

  return (
    <>
      <TaskMessage
        fontSize="16px"
        inline={1}
        prewrap={1}
        fontWeight={700}
        sx={{ marginBottom: '12px', marginRight: '6px' }}
      >
        {' for order'}
      </TaskMessage>
      <a rel="noreferrer" href={pathForOrderByNumber} target="_blank">
        <Typography component="span" fontWeight={500} fontSize="14px" color={theme.palette.secondary.main}>
          {`#${originalOrderNumber} `}
        </Typography>
      </a>
      <Stack gap="12px">
        <Stack gap="6px">
          <TaskMessage fontWeight={700} color="grey.700">
            Tests
          </TaskMessage>
          <List sx={classes.list}>
            {tests?.map(test => (
              <ListItem key={test} sx={classes.li}>
                <Marker />
                {test}
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack direction="row" gap="12px" alignItems="center">
          <TaskMessage fontWeight={700} color="grey.700">
            Fasting
          </TaskMessage>
          <Typography variant="label14Medium" color="grey.700">
            {fasting ? FastingVisible[fasting] : '-'}
          </Typography>
        </Stack>

        <TaskMessageInline fontWeight={700}>
          {'Primary diagnosis '}
          <TaskMessage inline={1} sx={{ display: 'inline' }}>
            {primaryDiagnosis ?? ''}
          </TaskMessage>
        </TaskMessageInline>
        {secondariesDiagnoses && secondariesDiagnoses.length > 0 && (
          <Stack gap="6px">
            <TaskMessage fontWeight={700}>Secondary diagnoses</TaskMessage>
            <List sx={classes.list}>
              {secondariesDiagnoses.map(diagnosis => (
                <ListItem sx={classes.li} key={diagnosis}>
                  <Marker />
                  {diagnosis}
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
        <TaskMessageInline fontWeight={700}>
          {'Ordering Provider '}
          <TaskMessage inline={1}>{orderingProvider}</TaskMessage>
        </TaskMessageInline>
        <TaskMessageInline fontWeight={700} sx={{ mb: body ? 0 : '12px' }}>
          {'Billing Type '}
          <TaskMessage inline={1}>{billType}</TaskMessage>
        </TaskMessageInline>
        {body && (
          <TaskMessageInline fontWeight={700} mb="12px">
            {'Message '}
            <TaskMessage inline={1}>{body}</TaskMessage>
          </TaskMessageInline>
        )}
      </Stack>
    </>
  );
};
