import { Stack } from '@mui/system';
import { useState, useContext, useEffect } from 'react';
import { ChatsContext } from 'src/context/chats';
import { PortalContext } from 'src/context/portal';
import { Chat } from './Chat';
import { Header } from './Header';
import { setNeededChat } from './helpers';
import { SendMessage } from './SendMessage/SendMessage';
import { TaskModal } from './TaskModal';
import { TestRequest } from './TestRequest';

export const Dialog = () => {
  const [isOpenTaskModal, setIsOpenTaskModal] = useState<boolean>(false);
  const { activeChat, chats } = useContext(ChatsContext);
  const { patientName, patientId, orderId, labCode, conversationId } = useContext(PortalContext);
  const [isOpenTestRequestModal, setIsOpenTestRequestModal] = useState<boolean>(false);

  useEffect(() => {
    if (chats.length > 0) setNeededChat(chats, patientId, labCode, conversationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  return activeChat || patientName || orderId ? (
    <Stack sx={{ height: '100%' }}>
      <Header setIsOpenTaskModal={setIsOpenTaskModal} setIsOpenTestRequestModal={setIsOpenTestRequestModal} />
      <Chat />
      <SendMessage />
      <TaskModal isOpenTaskModal={isOpenTaskModal} setIsOpenTaskModal={setIsOpenTaskModal} patientId={patientId} />
      <TestRequest
        isOpenTestRequestModal={isOpenTestRequestModal}
        setIsOpenTestRequestModal={setIsOpenTestRequestModal}
      />
    </Stack>
  ) : null;
};
