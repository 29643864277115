import { format, formatDistanceToNow } from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';

export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const ISO_DATE_8601_FORMAT = 'yyyy-MM-dd';

export const formatDate = (dateFormat: string, date?: string | Date) => {
  if (!date || date == null) {
    return null;
  }

  return format(new Date(new Date(date).toISOString().split('T')[0]), dateFormat);
};

export const formatTime = (dateTime?: string | Date | null | undefined) => {
  if (!dateTime || dateTime == null) {
    return '';
  }

  return format(new Date(dateTime), 'hh:mm aa');
};

export const formatToUsDateWithoutTime = (date?: string | Date): string | null => {
  if (!date || date == null) {
    return null;
  }

  return formatDate(US_DATE_FORMAT, date);
};

export const getDisplayDate = (dateString: string | Date | null | undefined) => {
  if (!dateString || dateString === null) {
    return '';
  }

  return formatToUsDateWithoutTime(dateString) || '';
};

export const formatDistanceToNowUS = (dateString: Date | string | number | null | undefined) => {
  if (!dateString || dateString === null) {
    return '';
  }

  const valid = dateString && Date.parse(dateString.toString()) >= 0;

  if (!valid) {
    return '';
  }

  return formatDistanceToNow(new Date(dateString), { locale: enUS });
};

export const formatUTCDate = (dateString: string | Date) => {
  const date = utcToZonedTime(dateString, 'UTC');

  return formatTZ(date, US_DATE_FORMAT, { timeZone: 'UTC' });
};
