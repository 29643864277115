import React, { useState } from 'react';
import { SearchValueContext } from './context';

type PropsProvider = {
  children: JSX.Element;
};

type SearchValueAction = null | {
  setSearchValue: (value: string) => void;
  setSearchValueConversation: (value: string) => void;
};

export let searchValueActions: SearchValueAction = null;

export const SearchValueProvider: React.FC<PropsProvider> = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueConversation, setSearchValueConversation] = useState<string>('');

  searchValueActions = {
    setSearchValue: (value: string) => {
      setSearchValue(value);
    },
    setSearchValueConversation: (value: string) => {
      setSearchValueConversation(value);
    },
  };

  return (
    <SearchValueContext.Provider value={{ searchValue, searchValueConversation }}>
      {children}
    </SearchValueContext.Provider>
  );
};
