import { createTheme } from '@mui/material/styles';
import { hexOpacity } from 'src/utils';

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Select' {
  interface TextFieldPropsColorOverrides {
    outlined_fill: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariantsOptions {
    head36Bold?: React.CSSProperties;
    head32Bold?: React.CSSProperties;
    head32Medium?: React.CSSProperties;
    head24Bold?: React.CSSProperties;
    head24Medium?: React.CSSProperties;
    head22Bold?: React.CSSProperties;
    head22Medium?: React.CSSProperties;
    head24Regular?: React.CSSProperties;
    head20Bold?: React.CSSProperties;
    head20Medium?: React.CSSProperties;
    head20Regular?: React.CSSProperties;

    body18Bold?: React.CSSProperties;
    body18Medium?: React.CSSProperties;
    body18Regular?: React.CSSProperties;
    body16Bold?: React.CSSProperties;
    body16Medium?: React.CSSProperties;
    body16Regular?: React.CSSProperties;

    label14Bold?: React.CSSProperties;
    label14Medium?: React.CSSProperties;
    label14Regular?: React.CSSProperties;
    label12Bold?: React.CSSProperties;
    label12Medium?: React.CSSProperties;
    label12Regular?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    head36Bold?: true;
    head32Bold?: true;
    head32Medium?: true;
    head24Bold?: true;
    head24Medium?: true;
    head24Regular?: true;
    head22Bold?: true;
    head22Medium?: true;
    head20Bold?: true;
    head20Medium?: true;
    head20Regular?: true;

    body18Bold?: true;
    body18Medium?: true;
    body18Regular?: true;
    body16Bold?: true;
    body16Medium?: true;
    body16Regular?: true;

    label14Bold?: true;
    label14Medium?: true;
    label14Regular?: true;
    label12Bold?: true;
    label12Medium?: true;
    label12Regular?: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    errorInput: {
      main: string;
    };
  }

  interface Palette {
    errorInput: {
      main: string;
    };
  }
}

const borderBox = 'border-box';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#A81D42',
      light: '#DF546D',
      dark: '#73001C',
    },
    secondary: {
      main: '#19A1B7',
      light: '#6EC4D2',
      dark: '#007287',
    },
    text: {
      primary: '#4B5563',
    },
    background: {
      default: '#EEF5F8',
      paper: '#FFFFFF',
    },
    grey: {
      '100': '#FAFAFA',
      '200': '#F3F4F6',
      '300': '#C7CDD6',
      '400': '#999FAB',
      '500': '#858D9C',
      '600': '#6B7280',
      '700': '#4B5563',
      '800': '#374151',
    },
    warning: {
      main: '#FAB41C',
      light: '#FEF7E8',
    },
    error: {
      main: '#F4E3E6',
      light: '#FDECEF',
      dark: '#DF546D',
    },
    errorInput: {
      main: '#EA3D5C',
    },
    success: {
      main: '#DDEFEE',
      light: '#E6F8F7',
      dark: '#05B7AC',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ['rawline', 'sans-serif'].join(','),
    head36Bold: { fontSize: 36, fontWeight: 700 },
    head32Bold: { fontSize: 32, fontWeight: 700 },
    head32Medium: { fontSize: 32, fontWeight: 500 },
    head24Bold: { fontSize: 24, fontWeight: 700 },
    head24Medium: { fontSize: 24, fontWeight: 500 },
    head24Regular: { fontSize: 24, fontWeight: 400 },
    head22Bold: { fontSize: 22, fontWeight: 700 },
    head22Medium: { fontSize: 22, fontWeight: 500 },
    head20Bold: { fontSize: 20, fontWeight: 700 },
    head20Medium: { fontSize: 20, fontWeight: 500 },
    head20Regular: { fontSize: 20, fontWeight: 400 },
    body18Bold: { fontSize: 18, fontWeight: 700 },
    body18Medium: { fontSize: 18, fontWeight: 500 },
    body18Regular: { fontSize: 18, fontWeight: 400 },
    body16Bold: { fontSize: 16, fontWeight: 700 },
    body16Medium: { fontSize: 16, fontWeight: 500 },
    body16Regular: { fontSize: 16, fontWeight: 400 },
    label14Bold: { fontSize: 14, fontWeight: 700 },
    label14Medium: { fontSize: 14, fontWeight: 500 },
    label14Regular: { fontSize: 14, fontWeight: 400 },
    label12Bold: { fontSize: 12, fontWeight: 700 },
    label12Medium: { fontSize: 12, fontWeight: 500 },
    label12Regular: { fontSize: 12, fontWeight: 400 },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 600,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#dee9ed',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.grey[600],
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiButton: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: ({ ownerState }) => ({
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
          ...(ownerState.variant === 'outlined' && {
            color: theme.palette[ownerState.color as 'primary' | 'secondary'].main,
            borderColor: theme.palette[ownerState.color as 'primary' | 'secondary'].main,
            '&:hover': {
              background: 'none',
              color: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              borderColor: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              'svg path': {
                fill: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              },
            },
            '&:active': {
              background: 'none',
              color: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,
              borderColor: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,
              'svg path': {
                fill: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,
              },
            },
          }),
          textTransform: 'initial',
          ...(ownerState.variant === 'contained' && {
            '&.Mui-disabled': {
              background: theme.palette.grey['200'],
              color: theme.palette.grey['300'],
            },
            '&:hover': {
              background: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,
            },
          }),
          ...(ownerState.variant === 'text' && {
            '&:hover': {
              color: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              background: 'none',
              'svg path': {
                fill: theme.palette[ownerState.color as 'primary' | 'secondary'].light,
              },
            },
            '&:active': {
              color: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,

              'svg path': {
                fill: theme.palette[ownerState.color as 'primary' | 'secondary'].dark,
              },
            },
          }),
        }),

        sizeLarge: {
          height: '54px',
          padding: '12px 54px',
        },
        sizeMedium: {
          height: 42,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // eslint-disable-next-line  unicorn/no-useless-spread
        ...{
          root: () => ({
            '& .MuiInputBase-input': {
              padding: '10px 0 12px 12px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.MuiOutlinedInput-root.Mui-focused': {
              background: '#fff',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: `2px solid ${theme.palette.errorInput.main}`,
            },
          }),
          select: {
            '&.MuiOutlinedInput-root.Mui-focused': {
              background: '#fff',
            },
          },
          icon: {
            right: '12px',
            top: 'auto',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: () => ({
          boxSizing: borderBox,
          backgroundColor: '#fff',
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey['500'],
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
          },
          '&.Mui-disabled': {
            background: 'none',
          },
          '& > div': {
            padding: '6px 0 6px 0',
          },
          '& > div > div ': {
            color: '#abb3bd',
          },
          '& .MuiInputBase-root ': {
            height: 56,
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: () => ({
          borderColor: hexOpacity(theme.palette.secondary.main, '14%'),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: () => ({
          boxSizing: borderBox,
          borderRadius: theme.shape.borderRadius,
          color: theme.palette.grey['800'],
          height: 42,
          fontSize: '14px',
          '& fieldset': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.grey['300'],
          },
          '&.Mui-error fieldset': {
            top: '-4px',
            border: `2px solid ${theme.palette.errorInput.main}`,
          },
          '&.Mui-disabled': {
            background: theme.palette.grey['200'],
          },
          '& > div > div ': {
            color: '#abb3bd',
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: theme.palette.grey[800],
          margin: 0,
          ...(ownerState.labelPlacement === 'top' && { alignItems: 'flex-start' }),
          '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
          },
          '& .MuiInputBase-root': {
            '& .MuiSelect-select': {
              '&:focus': {
                background: '#fff',
              },
            },
            '&:hover:not(.Mui-disabled, .Mui-error)': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey['400'],
              },
            },
            '&.Mui-focused:not(.Mui-disabled, .Mui-error)': {
              background: '#fff',
              '& .MuiOutlinedInput-notchedOutline': {
                top: '-4px',
                border: `2px solid ${theme.palette.grey['500']}`,
              },
            },
            '& .MuiFormHelperText-root': {
              height: 0,
            },
          },
        }),
        label: () => ({
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          flexShrink: 0,
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          '& .MuiInputBase-root': {
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                background: '#fff',
              },
            },
            '&:hover:not(.Mui-disabled, .Mui-error)': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey['400'],
              },
            },
            '&.Mui-focused:not(.Mui-disabled, .Mui-error)': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${theme.palette.grey['500']}`,
              },
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          fontSize: '14px',
          color: theme.palette.grey['800'],
          fontWeight: 500,
          background: '#fff',

          '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .MuiFormHelperText-root': {
            height: 0,
          },
          '&:hover:not(.Mui-disabled, .Mui-error)': {
            background: '#fff',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey['400'],
            },
          },
          '&.Mui-focused:not(.Mui-disabled, .Mui-error)': {
            background: '#fff',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
          '&.MuiInput-root.Mui-disabled': {
            background: theme.palette.grey['200'],
            ':before': {
              borderBottomStyle: 'solid',
            },
          },
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: () => ({
          '&:hover:not(.Mui-disabled, .Mui-error):before, :before': {
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
          },
          '&:after': {
            borderBottom: 'transparent',
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          '& .Mui-focused': {
            background: '#fff',
          },
          '& .MuiOutlinedInput-root': {
            padding: '11px',
            '&.Mui-focused': {
              background: '#fff',
            },
            '& .MuiAutocomplete-input': {
              padding: '0',
              minWidth: '5px',
              height: 'auto',
            },
            '&.MuiInputBase-sizeSmall': {
              padding: '0 42px 0 12px',
              '& .MuiAutocomplete-input': {
                padding: 0,
              },
            },
          },
        }),
        clearIndicator: {
          padding: '0 4px',
        },
        endAdornment: {
          right: '9px',
        },
        tag: { margin: '2px 2px 0 0' },
        paper: {
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
        },
        listbox: () => ({
          padding: 0,
          maxHeight: '200px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: hexOpacity(theme.palette.secondary.main, '14%'),
          },
          '& > li': {
            fontSize: '14px',
            fontWeight: 500,
            '&.MuiAutocomplete-option': {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '12px',
              minHeight: '40px',
              '&:hover': {
                ' span': {
                  color: theme.palette.secondary.main,
                },
                backgroundColor: hexOpacity(theme.palette.secondary.main, '7%'),
              },
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              background: 'none',
            },
            '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused,&.MuiAutocomplete-option:hover': {
              color: theme.palette.secondary.main,
              background: `#EEF5F8 `,
            },
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
        },
        colorPrimary: () => ({
          background: hexOpacity(theme.palette.secondary.main, '7%'),
          color: theme.palette.secondary.main,
          '&:hover': {
            background: hexOpacity(theme.palette.secondary.main, '14%'),
          },
          '&:active': {
            background: hexOpacity(theme.palette.secondary.main, '21%'),
            color: theme.palette.secondary.dark,
          },
        }),
      },
    },
    MuiIconButton: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: () => ({
          padding: '9px',
          '&:hover': {
            '& svg path': {
              fill: theme.palette.grey['500'],
            },
            '&:active': {
              '& svg path': {
                fill: theme.palette.grey['700'],
              },
            },
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.secondary.main,
          borderRadius: 0,
          '&:hover': {
            background: 'none',
          },
          '&.Mui-disabled': {
            'svg path:first-of-type': {
              stroke: '#C7CDD6',
            },
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: () => ({
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
        }),
        root: () => ({
          '& .MuiList-root ': {
            border: 'none',
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          marginTop: 0,
          marginLeft: 0,
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 500,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: () => ({
          padding: 0,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: () => ({
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
              },
              transform: 'translateX(26px)',
              '&:hover': {
                background: 'none',
              },
            },
            '&:hover': {
              background: 'none',
            },
            transform: 'translateX(-4px)',
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#FFFFFF',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.grey[400],
            height: '8px',
            transform: 'translateY(50%)',
            width: '41px',
          },
          '&:active .MuiSwitch-thumb': {
            backgroundColor: '#EFF8FA',
          },
          '&:hover .Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.secondary.dark,
          },
          '&:hover .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[600],
          },
        }),
      },
    },
  },
});
