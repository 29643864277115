import React, { useRef } from 'react';

export const useObserver = (
  callBack: IntersectionObserverCallback,
  ref: React.RefObject<HTMLDivElement | HTMLButtonElement>,
  loadig?: boolean,
) => {
  const observerLoader = useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    if (observerLoader.current) {
      observerLoader.current.disconnect();
    }
    observerLoader.current = new IntersectionObserver(callBack, { root: null, rootMargin: '0px' });
    if (ref.current && !loadig) {
      observerLoader.current.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
  return { ref };
};
