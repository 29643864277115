import React, { useContext, useEffect, useRef, useState } from 'react';
import { Message } from './Message';
import { useChatScroll } from 'src/hooks/useChatScroll';
import {
  ChatWrapper,
  ChevronButton,
  HeaderSearchingMessages,
  MessageConversationList,
  SearchedMessages,
} from './styled';
import { ChatsContext, chatsActions } from 'src/context/chats';
import { SearchValueContext } from 'src/context/searchValue';
import { messagesActions, MessagesContext } from 'src/context/messages';
import { useObserver } from 'src/hooks/useObserver';
import { TAKE_PAGER_MESSAGE } from 'src/const';
import { Stack, Typography } from '@mui/material';
import { TMessageItem, TScrollMessageValue } from 'src/api/messages/models';
import { TChatItem } from 'src/api/chats/models';
import { portalActions, PortalContext } from 'src/context/portal';
import { isSameDay } from 'date-fns';
import { readMessages } from 'src/api/messages/messages';
import { PulseLoader } from 'react-spinners';
import { MessageSearchItem } from '../MessageSearchItem';
import { ReactComponent as CU24 } from 'src/icons/24х24/Chevron-up.svg';
import { ReactComponent as CD24 } from 'src/icons/24х24/Chevron-down.svg';

export const Chat: React.FC = () => {
  const { activeChat, createdActiveChat, searchedMessage, chats, typeActiveChat } = useContext(ChatsContext);
  const {
    messages,
    isFetching,
    isEndTopMessage,
    isEndBottomMessage,
    firstLoading,
    searchMessagesConversation,
    isViewFoundMessagesConversation,
  } = useContext(MessagesContext);
  const { searchValue, searchValueConversation } = useContext(SearchValueContext);
  const { isNewPatient, anchor } = useContext(PortalContext);
  const [visibilityMessages, setVisibitilyMessages] = useState<boolean>(true);
  const [isBottom, setIsBottom] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const fixedBlockRefs = useRef<HTMLDivElement[]>([]);
  const [selectedMessage, setSelectedMessage] = useState({ index: -1, created: '' });

  useEffect(() => {
    if (searchMessagesConversation.length > 0 && searchValueConversation) {
      messagesActions.setIsViewFoundMessagesConversation(true);
    }
  }, [searchMessagesConversation.length, searchValueConversation]);

  const clickUp = () => {
    if (searchMessagesConversation.length > 0) {
      if (!selectedMessage.created) {
        setSelectedMessage(prev => ({ ...prev, index: 0, created: searchMessagesConversation[0].created }));
        if (isViewFoundMessagesConversation) messagesActions.setIsViewFoundMessagesConversation(false);
        chatsActions.setAnchorMessage(searchMessagesConversation[0].created);
      } else {
        setSelectedMessage(prev => ({
          ...prev,
          index: selectedMessage.index + 1,
          created: searchMessagesConversation[selectedMessage.index + 1].created,
        }));
        if (isViewFoundMessagesConversation) messagesActions.setIsViewFoundMessagesConversation(false);
        chatsActions.setAnchorMessage(searchMessagesConversation[selectedMessage.index + 1].created);
      }
    }
  };

  const clickDown = () => {
    if (searchMessagesConversation.length > 1 && selectedMessage.index >= 1) {
      setSelectedMessage(prev => ({
        ...prev,
        index: selectedMessage.index - 1,
        created: searchMessagesConversation[selectedMessage.index - 1].created,
      }));
      if (isViewFoundMessagesConversation) messagesActions.setIsViewFoundMessagesConversation(false);
      chatsActions.setAnchorMessage(searchMessagesConversation[selectedMessage.index - 1].created);
    }
  };

  useObserver(
    entries => {
      const entry = entries[0];
      setIsBottom(entry.isIntersecting);
    },
    bottomRef,
    isFetching,
  );

  useEffect(() => {
    const handlerVisibility = (e: any) => {
      setVisibitilyMessages(e.type === 'blur' ? false : true);
    };
    window.addEventListener('blur', handlerVisibility);
    window.addEventListener('focus', handlerVisibility);
    return () => {
      window.removeEventListener('blur', handlerVisibility);
      window.removeEventListener('focus', handlerVisibility);
    };
  }, []);

  const scrollTop = (entries: IntersectionObserverEntry[]) => {
    const valueTop: TScrollMessageValue = {
      conversationId: activeChat,
      anchor: messages[0]?.created,
      takeBefore: TAKE_PAGER_MESSAGE,
    };
    if (
      entries[0].isIntersecting &&
      !(messages.length < TAKE_PAGER_MESSAGE) &&
      !isFetching &&
      !isEndTopMessage &&
      !searchValueConversation
    ) {
      void messagesActions?.scrollMessageAction(valueTop, isEndTopMessage);
    }
  };
  const scrollBottom = (entries: IntersectionObserverEntry[]) => {
    const valueBottom: TScrollMessageValue = {
      conversationId: activeChat,
      anchor: messages[messages.length - 1].created,
      takeAfter: TAKE_PAGER_MESSAGE,
    };
    if (entries[0].isIntersecting && !(messages.length < TAKE_PAGER_MESSAGE) && !isFetching && !isEndBottomMessage) {
      void messagesActions?.scrollMessageAction(valueBottom, isEndBottomMessage);
    }
  };
  const lastItem = React.createRef<HTMLDivElement>();
  const middleItem = React.createRef<HTMLDivElement>();
  useObserver(scrollTop, middleItem);
  useObserver(scrollBottom, lastItem);
  useChatScroll(messages, lastItem, searchValue, isBottom);

  useEffect(() => {
    if (!searchValue && activeChat && !anchor) {
      messagesActions?.resetLoadingAllMessage(false);
      const firstMessageActiveChat = chats.find((item: TChatItem) => item.id === activeChat)
        ?.lastMessage as TMessageItem;
      if (!isNewPatient) {
        void messagesActions?.getChatMessages(
          {
            conversationId: activeChat,
            anchor: firstMessageActiveChat?.created || '',
            takeBefore: TAKE_PAGER_MESSAGE,
          },
          firstMessageActiveChat,
        );
      }
      portalActions?.resetParams();
    } else if (searchValue) {
      messagesActions?.resetLoadingAllMessage(false);
    } else if (anchor) {
      messagesActions?.resetLoadingAllMessage(true);
      chatsActions?.setAnchorMessage(anchor);
      portalActions?.resetParams();
    }
    messagesActions?.resetIsScrollTop(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat, searchValue, isNewPatient, anchor]);

  useEffect(() => {
    if (searchValue) {
      messagesActions?.resetLoadingAllMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdActiveChat]);

  useEffect(() => {
    const valueForScroll = {
      conversationId: activeChat,
      anchor: searchedMessage,
      takeBefore: TAKE_PAGER_MESSAGE,
      takeAfter: TAKE_PAGER_MESSAGE,
    };
    if (searchedMessage) {
      const messageIsLoad = messages.find(message => message.created === searchedMessage);
      if (!messageIsLoad) {
        void messagesActions?.scrollMessageAction(valueForScroll);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedMessage]);

  useEffect(() => {
    if (messages.length > 0 && visibilityMessages) {
      // Прочтение сообщений
      const idsUnread = messages
        .filter((message: TMessageItem) => !message.isRead && !message.fromCurrentModule)
        ?.map(filterMessage => filterMessage.id) as string[];
      if (idsUnread.length > 0) {
        void readMessages({ ids: idsUnread, conversationId: activeChat });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, visibilityMessages]);

  // динамическое изменение ширины плашки и блока найденных сообщений
  useEffect(() => {
    const updateBlockWidths = () => {
      const containerWidth = chatContainerRef.current?.offsetWidth || 0;
      fixedBlockRefs.current.forEach(block => {
        if (block) {
          block.style.width = `${containerWidth}px`;
        }
      });
    };

    updateBlockWidths();

    window.addEventListener('resize', updateBlockWidths);
    return () => window.removeEventListener('resize', updateBlockWidths);
  }, []);

  return (
    <ChatWrapper typeActiveChat={typeActiveChat} ref={chatContainerRef}>
      <HeaderSearchingMessages
        ref={(el: HTMLDivElement) => (fixedBlockRefs.current[0] = el)}
        sx={{
          display: searchValueConversation ? 'flex' : 'none',
        }}
      >
        <Typography variant="label14Medium" color="grey.700" lineHeight="18px">
          {/* eslint-disable  sonarjs/no-nested-template-literals */}
          {`${
            searchMessagesConversation.length > 0
              ? `${selectedMessage.index === -1 ? '' : `${selectedMessage.index + 1}/`}${
                  searchMessagesConversation.length
                }`
              : 'No messages'
          } found`}
        </Typography>
        <Stack flexDirection="row">
          <ChevronButton onClick={clickUp} disabled={selectedMessage.index === searchMessagesConversation.length - 1}>
            <CU24 />
          </ChevronButton>
          <ChevronButton onClick={clickDown} disabled={selectedMessage.index === 0}>
            <CD24 />
          </ChevronButton>
        </Stack>
      </HeaderSearchingMessages>
      <SearchedMessages
        ref={(el: HTMLDivElement) => (fixedBlockRefs.current[1] = el)}
        sx={{
          display: isViewFoundMessagesConversation ? 'block' : 'none',
        }}
      >
        {searchMessagesConversation.length > 0 ? (
          <MessageConversationList>
            {searchMessagesConversation.map((elem, index) => (
              <MessageSearchItem
                selectedMessage={selectedMessage}
                index={index}
                setSelectedMessage={setSelectedMessage}
                message={elem}
                key={`${elem.created}${index}`}
              />
            ))}
          </MessageConversationList>
        ) : null}
      </SearchedMessages>
      <Stack height="100%">
        {!firstLoading ? (
          messages?.map((item, index, array) => (
            <Message
              typeActiveChat={typeActiveChat}
              key={item.id}
              item={item}
              withNewDay={!isSameDay(new Date(item.created), new Date(array[index - 1]?.created))}
              ref={index === messages.length - 1 ? lastItem : index === TAKE_PAGER_MESSAGE / 2 ? middleItem : null}
            />
          ))
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{
              '@keyframes react-spinners-PulseLoader-pulse': {
                '0%': {
                  transform: 'scale(1)',
                  opacity: 1,
                },
                '45%': {
                  transform: 'scale(0.1)',
                  opacity: 0.7,
                },
                '80%': {
                  transform: 'scale(1)',
                  opacity: 1,
                },
              },
            }}
          >
            <PulseLoader color="#0096b2" size={20} />
          </Stack>
        )}
      </Stack>
      <div ref={bottomRef} />
    </ChatWrapper>
  );
};
