import { ListItem, Typography, styled } from '@mui/material';
import { border } from 'src/const';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils/hexOpacity';

const UnreadChat = styled(Typography)`
  border-radius: 50%;
  background: ${theme.palette.secondary.main};
  color: #ffffff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  flex-shrink: 0;
`;

const ChatItem = styled(ListItem)`
  padding: 0;
  cursor: pointer;
  border-left: 4px solid transparent;
  border-bottom: ${border};
  flex-shrink: 0;
  &:hover {
    background: ${theme.palette.grey[100]};
  }
  &.Mui-selected {
    background: ${hexOpacity(theme.palette.secondary.main, '7%')};
  }
`;

export { UnreadChat, ChatItem };
