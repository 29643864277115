import { TChatList, TChatItem, SearchType } from './models';
import API from '../api';
import { TCreateChatPatient } from '../messages/models';
import { Pager } from '../common/models';

const PATH_CHATS = '/conversations';

const createPatientChat = (conversation: TCreateChatPatient) =>
  API.post<TChatItem>(`${PATH_CHATS}/patient`, conversation).then(result => result);

const getChats = (value: Pager, unreadOnly: boolean) =>
  API.post<TChatList>(`${PATH_CHATS}/load`, { pager: value, unreadOnly: unreadOnly }).then(result => result);

const searchChats = (searchTerm: string, value: Pager) =>
  API.post<TChatItem[]>(`${PATH_CHATS}/search`, { searchTerm: searchTerm, pager: value }).then(result => result);

const getChatByIdentifier = (id: string, searchType: SearchType) => {
  if (searchType === SearchType.ByLabcode) {
    return API.get<TChatItem[]>(`${PATH_CHATS}/laboratory/${id}`).then(result => result);
  }
  if (searchType === SearchType.ByConversationId) {
    return API.get<TChatItem[]>(`${PATH_CHATS}/${id}`).then(result => result);
  }
  return API.get<TChatItem[]>(`${PATH_CHATS}/patient/${id}`).then(result => result);
};

export { createPatientChat, getChats, searchChats, getChatByIdentifier };
