import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  ButtonBase,
  ButtonBaseProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';
import { hexOpacity } from 'src/utils/hexOpacity';
import { DiagnosesLocal } from './DiagnosesInput.helpers';
import { FC, forwardRef, memo, useMemo, useState } from 'react';
import { Checkbox, PreloaderBubbles } from 'src/components/ui';
import { theme as muiTheme } from 'src/theme';
import { getUnselectableDiagnoses } from 'src/api/portal/portal';
import { border } from 'src/const';

type CategoryButtonProps = {
  selected: number;
};
export const CategoryButton = styled(ButtonBase)<CategoryButtonProps & ButtonBaseProps>(({ selected, theme }) => ({
  padding: '6px 12px',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 600,
  color: selected ? theme.palette.secondary.main : theme.palette.grey['400'],
  backgroundColor: selected ? hexOpacity(theme.palette.secondary.main, '7%') : theme.palette.grey['200'],
}));

export const DiagnosesOption = styled(ButtonBase)(({ theme }) => ({
  padding: '14px 16px',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'start',
  gap: '12px 10px',
  borderBottom: border,
  '&:hover': {
    backgroundColor: theme.palette.grey['100'],
  },
  '&:active': {
    backgroundColor: hexOpacity(theme.palette.secondary.main, '7%'),
  },
}));

export const DiagnosisAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ArrowRight />} {...props} />
))(({ theme: localTheme }) => ({
  flexDirection: 'row-reverse',
  padding: '6px 12px 6px 6px',
  cursor: 'pointer',
  alignItems: 'start',
  minHeight: '30px',

  '& .MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '&.Mui-expanded': {
    minHeight: '30px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& svg': {
      margin: 0,
    },
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },

  '&:hover': {
    backgroundColor: localTheme.palette.grey['100'],
  },
  '&:active': {
    backgroundColor: hexOpacity(localTheme.palette.secondary.main, '7%'),
  },
}));

type DiagnosesOptionItemProps = {
  diagnosis: DiagnosesLocal;
  onClick: () => void;
  checked: boolean;
  disabled: boolean;
};
export const DiagnosesOptionItem = memo(
  forwardRef<HTMLButtonElement, DiagnosesOptionItemProps>(({ diagnosis, onClick, checked, disabled }, ref) => {
    const { code, name } = diagnosis;
    return (
      <DiagnosesOption onClick={onClick} ref={ref} disabled={disabled}>
        <Checkbox checked={checked} inputProps={{ 'aria-label': 'controlled' }} disabled={disabled} />
        <Typography color="grey.700" fontSize="14px" lineHeight="18px" fontWeight={500}>
          {code}
        </Typography>
        <Typography color="grey.700" fontSize="12px" lineHeight="18px" fontWeight={400} textAlign="start">
          {name}
        </Typography>
      </DiagnosesOption>
    );
  }),
);

type DiagnosesCategoryProps = {
  diagnosis: DiagnosesLocal;
  setParentDiagnosisEsbId: (val: string) => void;
  lastItemCategoryDiagnosis: any;
  level: number;
  parentDiagnosisEsbId?: string;
};

export const DiagnosesCategory: FC<DiagnosesCategoryProps> = ({
  diagnosis,
  setParentDiagnosisEsbId,
  lastItemCategoryDiagnosis,
  level,
  parentDiagnosisEsbId,
}) => {
  const { code, esbId, name } = diagnosis;
  const [allDiagnosesChildrenCategory, setAllDiagnosesChildrenCategory] = useState<DiagnosesLocal[] | undefined>([]);
  const selected = esbId === parentDiagnosisEsbId;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickDiagnosisChildren = async () => {
    setParentDiagnosisEsbId(esbId);
    setLoading(true);
    await getUnselectableDiagnoses({
      search: '',
      parentDiagnosisEsbId: esbId,
    }).then(res => {
      setAllDiagnosesChildrenCategory(res);
      return setLoading(false);
    });
  };

  const onChangeAccordion = async (_ev: any, isOpen: boolean) => {
    if (isOpen) {
      setOpen(true);
      await onClickDiagnosisChildren();
    } else {
      setOpen(false);
    }
  };

  const summaryFontStyle = useMemo(() => {
    switch (level) {
      case 1: {
        return { fontWeight: 700 };
      }
      case 2: {
        return { fontWeight: 700 };
      }
      case 3: {
        return { fontWeight: 600 };
      }
      case 4: {
        return { fontWeight: 600 };
      }
      default: {
        return { fontWeight: 500 };
      }
    }
  }, [level]);

  return (
    <Accordion
      key={`${code}/${esbId}`}
      sx={{
        boxShadow: 'none',
        '&:before': {
          backgroundColor: '#fff',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      }}
      ref={lastItemCategoryDiagnosis || null}
      expanded={open}
      onChange={onChangeAccordion}
    >
      <DiagnosisAccordionSummary
        sx={selected ? { backgroundColor: hexOpacity(muiTheme.palette.secondary.main, '7%') } : {}}
      >
        {loading && <PreloaderBubbles size={6} />}
        <Stack ml="6px" direction="row" gap="12px">
          <Typography
            color="grey.700"
            sx={{ flexShrink: 0, fontSize: '14px', lineHeight: '16px', ...summaryFontStyle }}
          >
            {code}
          </Typography>
          <Typography color="grey.700" sx={{ fontSize: '14px', lineHeight: '16px', ...summaryFontStyle }}>
            {name}
          </Typography>
        </Stack>
      </DiagnosisAccordionSummary>
      <AccordionDetails sx={{ padding: 0, paddingLeft: '12px' }}>
        {allDiagnosesChildrenCategory?.map(childrenDiagnosis => (
          <DiagnosesCategory
            lastItemCategoryDiagnosis={null}
            key={childrenDiagnosis.esbId}
            diagnosis={childrenDiagnosis}
            setParentDiagnosisEsbId={setParentDiagnosisEsbId}
            level={level + 1}
            parentDiagnosisEsbId={parentDiagnosisEsbId}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

DiagnosesCategory.displayName = 'DiagnosesCategory';
