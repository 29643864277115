import React, { useContext } from 'react';
import { PortalContext } from '../context/portal';
import api from './api';
import apiPortal from './apiPortal';

type PropsWithContext = {
  children: JSX.Element;
};

const WithContext: React.FC<PropsWithContext> = ({ children }) => {
  const { module, organizationId, organizationAddressId, hostingModuleUrl } = useContext(PortalContext);

  api.interceptors.request.use(request => {
    if (!request.headers) {
      request.headers = {};
    }
    request.headers['x-module'] = module;
    request.headers['x-org-id'] = organizationId;
    request.headers['x-org-addr-id'] = organizationAddressId;

    return request;
  });

  if (module === 'PROFESSIONAL') {
    apiPortal.interceptors.request.use(request => {
      if (hostingModuleUrl && !request.baseURL?.includes(hostingModuleUrl)) {
        request.baseURL = `${hostingModuleUrl}${request.baseURL}`;
      }

      return request;
    });
  }

  return children;
};

export default WithContext;
