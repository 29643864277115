import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { chatsActions, ChatsContext } from 'src/context/chats';
import Avatar from './Avatar';
import { ReactComponent as X18 } from 'src/icons/search.svg';
import { ReactComponent as C18 } from 'src/icons/close-lg.svg';
import {
  HeaderWrapper,
  Name,
  Role,
  CreateTaskButton,
  AddOnTestsRequestButton,
  PatientChartButton,
  SearchButton,
  CloseSearchButton,
} from './styled';
import { PortalContext } from 'src/context/portal';
import { ChatType, ModuleType } from 'src/api/common/models';
import { ProfileContext } from 'src/context/profile';
import { CLINICAL_TASK_PERMISSION, ORDERS_CHANGING_PERMISSION } from 'src/const';
import { messagesActions } from 'src/context/messages';
import { SearchBlockConversation } from 'src/components/SearchBlockConversation';
import { SearchValueContext, searchValueActions } from 'src/context/searchValue';

type HeaderProps = {
  setIsOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenTestRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header: React.FC<HeaderProps> = React.memo(({ setIsOpenTaskModal, setIsOpenTestRequestModal }) => {
  const { chats, activeChat, typeActiveChat } = useContext(ChatsContext);
  const { patientName, patientId, module, isNewPatient, hostingModuleUrl } = useContext(PortalContext);
  const { permissions } = useContext(ProfileContext);
  const currentChat = chats.find(item => item.id === activeChat);
  const handleClickTaskModal = () => setIsOpenTaskModal(true);
  const handleClickTestRequestModal = () => setIsOpenTestRequestModal(true);
  const baseUrl = hostingModuleUrl === '/' ? hostingModuleUrl : `${hostingModuleUrl}/`;
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { searchValueConversation } = useContext(SearchValueContext);
  const [activeConv, setActiveConv] = useState('');

  useEffect(() => {
    if (activeConv === '') {
      setActiveConv(activeChat);
    } else if (activeConv !== activeChat) {
      setIsOpenSearch(false);
      setActiveConv(activeChat);
      searchValueActions?.setSearchValueConversation('');
      setSearchValue('');
    }
  }, [activeChat, activeConv]);

  const goToPatientChart = () => {
    window.open(`${baseUrl}patients/${currentChat?.patientId || patientId}`);
  };
  const isPatientModule = module === ModuleType.Patient;

  const clinicalTaskAvailable = permissions.includes(CLINICAL_TASK_PERMISSION);
  const orderChangingAvailable = permissions.includes(ORDERS_CHANGING_PERMISSION);

  useEffect(() => {
    // Устанавливаем тип чата для отображения нужных кнопок в хедере чата
    if (isNewPatient) {
      chatsActions.setChatType(ChatType.DoctorPatient);
    } else if (currentChat) {
      chatsActions.setChatType(currentChat.conversationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat, isNewPatient]);

  useEffect(() => {
    messagesActions?.resetSearchMessageConversation();
    if (searchValueConversation) {
      // добавить пагинацию, когда лимита в 1000 найденных сообщений по чату перестанет хватать
      void messagesActions?.searchMessageConversation(searchValueConversation, { take: 1000, skip: 0 }, activeChat);
    }
    if (!searchValueConversation) {
      messagesActions?.resetSearchMessageConversation();
    }
  }, [searchValueConversation, activeChat]);

  useEffect(() => {
    if (!searchValueConversation) messagesActions.setIsViewFoundMessagesConversation(false);
  }, [searchValueConversation]);

  return (
    <HeaderWrapper>
      {(currentChat?.name || patientName) && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Avatar name={currentChat?.name || patientName} src={currentChat?.conversationAvatarUrl} />
            <Box>
              <Name>{currentChat?.name || patientName}</Name>
              {!isPatientModule ? (
                <Role>{typeActiveChat === ChatType.DoctorPatient ? 'Patient' : 'Laboratory'}</Role>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            {module === ModuleType.Professional && typeActiveChat === ChatType.DoctorPatient && clinicalTaskAvailable && (
              <>
                <Stack flexDirection="row" display={!isOpenSearch ? 'flex' : 'none'}>
                  <CreateTaskButton variant="outlined" onClick={handleClickTaskModal}>
                    Send a task
                  </CreateTaskButton>
                  <PatientChartButton variant="contained" color="primary" onClick={goToPatientChart}>
                    Patient Chart
                  </PatientChartButton>
                </Stack>
              </>
            )}
            {module === ModuleType.Professional && typeActiveChat === ChatType.Laboratory && orderChangingAvailable && (
              <Stack display={!isOpenSearch ? 'flex' : 'none'}>
                <AddOnTestsRequestButton variant="contained" color="primary" onClick={handleClickTestRequestModal}>
                  Add On Tests Request
                </AddOnTestsRequestButton>
              </Stack>
            )}
            <Stack flexDirection="row">
              <Stack display={!isOpenSearch ? 'flex' : 'none'}>
                <SearchButton
                  variant="contained"
                  color="primary"
                  onClick={e => {
                    e.preventDefault();
                    setIsOpenSearch(true);
                  }}
                >
                  <X18 />
                </SearchButton>
              </Stack>
              <Stack display={isOpenSearch ? 'flex' : 'none'} flexDirection="row" alignItems="center">
                <SearchBlockConversation searchValue={searchValue} setSearchValue={setSearchValue} />
                <CloseSearchButton
                  onClick={() => {
                    searchValueActions?.setSearchValueConversation('');
                    setSearchValue('');
                    setIsOpenSearch(false);
                  }}
                >
                  <C18 />
                </CloseSearchButton>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </HeaderWrapper>
  );
});
