import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { border, borderBox } from 'src/const';
import { ReactComponent as ClearIcon } from 'src/icons/close-sm.svg';
import { ReactComponent as SearchIcon } from 'src/icons/search.svg';
import { searchValueActions } from 'src/context/searchValue';
import useDebounce from 'src/hooks/useDebounce';
import { Stack } from '@mui/system';

type SearchBlockProps = {
  isMobile?: boolean;
  isTablet?: boolean;
};
const classes = {
  box_wrapper: {
    borderBottom: border,
    boxSizing: borderBox,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
};

const styles = {
  width: '100%',
  '& .MuiInputBase-root': {
    gap: '0',
    height: '42px',
  },
};

export const SearchBlock: React.FC<SearchBlockProps> = ({ isMobile }) => {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 300);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const handleClearClick = () => {
    setValue('');
  };

  useEffect(() => {
    searchValueActions?.setSearchValue(debouncedValue);
  }, [debouncedValue]);
  return (
    <Stack
      sx={{
        ...classes.box_wrapper,
        padding: '0 24px',
        height: `${isMobile ? '80px' : '92px'}`,
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search"
        sx={styles}
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                margin: '0 8px 0 0',
              }}
            >
              <Stack direction="row" gap="4px" flexWrap="nowrap" alignItems="center">
                <SearchIcon />
              </Stack>
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{ visibility: value ? 'visible' : 'hidden', padding: '0', height: '100%' }}
              onClick={handleClearClick}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};
