import { Tooltip as MUITooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const Tooltip = styled(
  ({ className, ...props }: TooltipProps & { backgroundColor?: string; color?: string; fontWeight?: number }) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme, color, backgroundColor, fontWeight, sx }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor || theme.palette.grey[400],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor || theme.palette.grey[400],
    color,
    fontWeight: fontWeight || 500,
    ...sx,
  },
}));
