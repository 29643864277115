import { ChatType } from 'src/api/common/models';
import { TChatItem } from '../../api/chats/models';
import { TMessageItem } from 'src/api/messages/models';

export const initialStateChats: StateChats = {
  chats: [],
  searchChats: [],
  searchMessages: [],
  searchedMessage: '',
  activeChat: '',
  createdActiveChat: '',
  isEndOfAllChats: false,
  isEndOfAllSearchChats: false,
  isFetching: false,
  unreadOnly: false,
  typeActiveChat: ChatType.DoctorPatient,
  firstLoading: false,
};

export type StateChats = {
  /** Список чатов */
  chats: TChatItem[];
  /** Список чатов при поиске */
  searchChats: TChatItem[];
  /** Page of messages, found by search */
  searchMessages: TMessageItem[];
  /** Created сообщения при поиске (anchor для загрузки сообщений до/после)*/
  searchedMessage: string;
  /** Id активного чата */
  activeChat: string;
  /** Created активного чата, нужен для выделения чата при поиске, когда чатов с одним id может быть несколько */
  createdActiveChat: string;
  activeChatLabCode?: string | null;
  /** Флаг окончания списка чатов */
  isEndOfAllChats: boolean;
  /** Флаг окончания списка чатов при поиске */
  isEndOfAllSearchChats: boolean;
  /** Флаг загрузки чатов  */
  isFetching: boolean;
  /** Флаг для поиска прочитанных/непрочитанных чатов */
  unreadOnly: boolean;
  /** Тип чата */
  typeActiveChat: ChatType;
  /** Первая загузка чатов */
  firstLoading: boolean;
};

export enum ActionTypesChats {
  /** Инциализация массива с чатами */
  SET_CHATS = 'SET_CHATS',
  /** Добавление чатов в конец массива */
  ADD_CHATS_BOTTOM = 'ADD_CHATS_BOTTOM',
  SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT',
  SET_CREATED_ACTIVE_CHAT = 'SET_CREATED_ACTIVE_CHAT',
  SET_ACTIVE_CHAT_LABCODE = 'SET_ACTIVE_CHAT_LABCODE',
  /** Изменение чата при получении нового сообщения */
  CHANGE_CHATS_WITH_NEW_MESSAGE = 'CHANGE_CHATS_WITH_NEW_MESSAGE',
  /** Изменение чата при прочтении последнего сообщения */
  CHANGE_READ_LAST_MESSAGE = 'CHANGE_READ_LAST_MESSAGE',
  /** Изменение статуса последней задачи в чате */
  CHANGE_STATUS_LAST_TASK = 'CHANGE_STATUS_LAST_TASK',
  SET_FETCHING = 'SET_FETCHING',
  SET_FIRST_LOADING = 'SET_FIRST_LOADING',
  /** Установка конца списка чатов */
  SET_END_CHATS = 'SET_END_CHATS',
  /** Установка конца списка чатов при поиске */
  SET_END_SEARCH_CHATS = 'SET_END_SEARCH_CHATS',
  /** Добавление чатов при поиске в конец массива */
  ADD_SEARCH_CHAT_BOTTOM = 'ADD_SEARCH_CHAT_BOTTOM',
  SET_SEARCH_CHAT_INITIAL = 'SET_SEARCH_CHAT_INITIAL',
  SET_ID_SEARCH_MESSAGE = 'SET_ID_SEARCH_MESSAGE',
  SET_UNREAD_ONLY = 'SET_UNREAD_ONLY',
  ADD_CHAT_WITH_PATIENT = 'ADD_CHAT_WITH_PATIENT',
  SET_CHAT_TYPE = 'SET_CHAT_TYPE',
  SET_ID_ANCHOR_MESSAGE = 'SET_ID_ANCHOR_MESSAGE',
  ADD_CHAT_TO_TOP = 'ADD_CHAT_TO_TOP',
}
