import { dispatchMessages } from './provider';
import { ActionTypesMessages } from './const';
import axios from 'axios';
import { scrollMessage, postMessage, searchMessages, searchMessagesConversation } from 'src/api/messages/messages';
import { createPatientChat } from 'src/api/chats/chats';
import { sendAssignmentMakeAnAppointment } from 'src/api/assignments/assignments';
import { TMessageItem, TScrollMessageValue, TSendMessage } from 'src/api/messages/models';
import { TSendTaskMakeAnAppointment } from 'src/api/assignments/models';
import { ChatType, Pager } from 'src/api/common/models';

/**
 * @param flag флаг загрузки сообщений, значение true указывает, что запрос в настоящее время выполняется
 */
const setFetching = (flag: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_FETCHING,
    payload: flag,
  });
};

/**
 * @param flag флаг первой загрузки сообщений, значение true указывает, что запрос в данный момент загружается в первый раз и еще не имеет данных
 */
export const setFirstLoading = (flag: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_LOADING_FOR_PLELOADER,
    payload: flag,
  });
};

/**
 * Установка флага окончания списка сообщений сверху
 * @param flag флаг загрузки сообщений в топе
 */
const setEndTopMessage = (flag: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_END_TOP_MESSAGES,
    payload: flag,
  });
};

/**
 * Установка флага окончания списка сообщений снизу
 * @param flag флаг загрузки сообщений в топе
 */
const setEndBottomMessage = (flag: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_END_BOTTOM_MESSAGES,
    payload: flag,
  });
};
/**
 * Сброс флагов о полной загрузке сообщений
 */
export const resetLoadingAllMessage = (flag: boolean) => {
  setEndTopMessage(flag);
  setEndBottomMessage(flag);
};

/**
 * Сброс флага о скролле сообщений вверх
 */
export const resetIsScrollTop = (flag: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_IS_SCROLL_TOP,
    payload: flag,
  });
};

/**
 * Сброс сообщений активного чата
 */
export const resetChatsMessages = () => {
  dispatchMessages({
    type: ActionTypesMessages.SET_MESSAGES,
    payload: [],
  });
};

/**
 * Инциализация массива искомых сообщений
 */
export const resetSearchMessage = () => {
  dispatchMessages({
    type: ActionTypesMessages.SET_SEARCH_MESSAGES_INITIAL,
    payload: [],
  });
};

/**
 * Инциализация массива искомых сообщений в чате
 */
export const resetSearchMessageConversation = () => {
  dispatchMessages({
    type: ActionTypesMessages.SET_SEARCH_MESSAGES_CONVERSATION_INITIAL,
    payload: [],
  });
};
/**
 * Открытие/закрытие окна с найденными сообщениями
 */
export const setIsViewFoundMessagesConversation = (value: boolean) => {
  dispatchMessages({
    type: ActionTypesMessages.SET_IS_VIEW_FOUND_MESSAGES_CONVERSATION,
    payload: value,
  });
};

/**
 * Поиск по всем сообщениям
 */
export const searchMessage = async (message: string, value: Pager) => {
  try {
    setFetching(true);
    setFirstLoading(value.skip < 1);
    const messagesListSearch = await searchMessages(message, value);
    if (messagesListSearch?.data.length) {
      if (value.skip >= 0) {
        dispatchMessages({
          type: ActionTypesMessages.ADD_SEARCH_MESSAGES_BOTTOM,
          payload: messagesListSearch?.data,
        });
      } else resetSearchMessage();
    } else {
      dispatchMessages({
        type: ActionTypesMessages.SET_END_SEARCH_MESSAGES,
        payload: true,
      });
      if (value.skip < 1) {
        resetSearchMessage();
      }
    }
    setFetching(false);
    setFirstLoading(false);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Поиск сообщений по чату
 */
export const searchMessageConversation = async (message: string, value: Pager, conversationId: string) => {
  try {
    setFetching(true);
    setFirstLoading(value.skip < 1);
    const messagesListSearch = await searchMessagesConversation(message, value, conversationId);
    if (messagesListSearch?.data.length) {
      if (value.skip >= 0) {
        dispatchMessages({
          type: ActionTypesMessages.ADD_SEARCH_MESSAGES_CONVERSATION_BOTTOM,
          payload: messagesListSearch?.data,
        });
      } else resetSearchMessageConversation();
    } else {
      dispatchMessages({
        type: ActionTypesMessages.SET_END_SEARCH_MESSAGES_CONVERSATION,
        payload: true,
      });
      if (value.skip < 1) {
        resetSearchMessageConversation();
      }
    }
    setFetching(false);
    setFirstLoading(false);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Получение сообщения по очереди
 * @param message payload для загрузки сообщений
 * @param searchValue значение поиска по чатам (для вывода получаемого сообщения, если поиск не осуществляется)
 * @param activeChat id активного чата
 */
export const getMessage = (message: TMessageItem, searchValue: string, activeChat: string) => {
  if (!message.fromCurrentUser) {
    dispatchMessages({
      type: ActionTypesMessages.SET_IS_SENT_MESSAGE,
      payload: false,
    });
  }
  dispatchMessages({
    type: ActionTypesMessages.GET_MESSAGE_QUEUE,
    payload: { message, searchValue, activeChat },
  });
  setEndBottomMessage(true);
};

/**
 * Получение сообщений чата
 * @param value payload для загрузки сообщений
 * @param firstMessage сообщение, от которого идет загрузка сообщений чата
 */
export const getChatMessages = async (value: TScrollMessageValue, firstMessage?: TMessageItem) => {
  try {
    setFetching(true);
    setFirstLoading(true);
    const messageList = firstMessage ? await scrollMessage(value) : { data: [] };
    dispatchMessages({
      type: ActionTypesMessages.GET_CHAT_MESSAGE,
      payload: { messages: messageList.data.reverse(), firstMessage: firstMessage },
    });
    dispatchMessages({
      type: ActionTypesMessages.SET_LENGTH_MESSAGE_SEARCH,
      payload: messageList.data.length + 1,
    });
    setEndBottomMessage(true);
    setFetching(false);
    setFirstLoading(false);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Отправка сообщения
 * @param message payload для отправки сообщения
 * @param typeActiveChat тип чата, в который отправляется сообщение
 */
export const sendMessage = async (message: TSendMessage, typeActiveChat: ChatType) => {
  try {
    dispatchMessages({
      type: ActionTypesMessages.SET_IS_SENT_MESSAGE,
      payload: true,
    });
    if (typeActiveChat === ChatType.Laboratory) {
      await postMessage(message);
    } else if (typeActiveChat === ChatType.DoctorPatient) {
      // если чата с пациентом еще нет, то создаем и запоминаем его conversationId
      if (!message.conversationId) {
        const chat = await createPatientChat({ contactId: message.contactId });
        message.conversationId = chat.data.id;
      }
      await postMessage(message);
    }
    dispatchMessages({
      type: ActionTypesMessages.SET_IS_SENT_MESSAGE,
      payload: false,
    });
    setEndBottomMessage(true);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Отправка задачи пациенту
 * @param taskValue payload для отправки задачи
 */
export const sendTaskPatient = async (taskValue: TSendTaskMakeAnAppointment) => {
  try {
    // если чата с пациентом еще нет, то создаем и запоминаем его conversationId
    if (!taskValue.conversationId) {
      const chat = await createPatientChat({ contactId: taskValue.actorId });
      taskValue.conversationId = chat.data.id;
    }
    await sendAssignmentMakeAnAppointment(taskValue);
    setEndBottomMessage(true);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Загрузка сообщений при скролле и поиске
 * @param value payload для загрузки сообщений
 * @param isEndTopMessage конец массива сообщений сверху
 * @param isEndBottomMessage конец массива сообщений снизу
 */
export const scrollMessageAction = async (
  value: TScrollMessageValue,
  isEndTopMessage?: boolean,
  isEndBottomMessage?: boolean,
) => {
  try {
    // открытие чата при поиске по anchor, загружаем сообщения до и после anchor
    if (value.takeAfter && value.takeBefore) {
      setFetching(true);
      setFirstLoading(true);
      const messageList = await scrollMessage(value);
      dispatchMessages({
        type: ActionTypesMessages.SET_MESSAGES,
        payload: messageList.data.reverse(),
      });
      dispatchMessages({
        type: ActionTypesMessages.SET_LENGTH_MESSAGE_SEARCH,
        payload: messageList.data.length + 1,
      });
      resetIsScrollTop(true);
      resetLoadingAllMessage(false);
      setFirstLoading(false);
      setFetching(false);
      // скролл вверх, загружаем сообщения до anchor (если сверху еще есть сообщения)
    } else if (!isEndTopMessage && value.takeBefore) {
      setFetching(true);
      const messageList = await scrollMessage(value);
      if (messageList.data.length > 0) {
        dispatchMessages({
          type: ActionTypesMessages.ADD_MESSAGES_TOP,
          payload: messageList.data.reverse(),
        });
        resetIsScrollTop(true);
      } else setEndTopMessage(true);
      setFetching(false);
      // скролл вниз, загружаем сообщения после anchor (если снизу еще есть сообщения)
    } else if (!isEndBottomMessage && value.takeAfter) {
      setFetching(true);
      const messageList = await scrollMessage(value);
      if (messageList.data.length > 0) {
        dispatchMessages({
          type: ActionTypesMessages.ADD_MESSAGES_BOTTOM,
          payload: messageList.data.reverse(),
        });
      } else setEndBottomMessage(true);
      setFetching(false);
    }
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Изменение состояния прочтения сообщений в открытом чате
 * @param ids массив прочитанных сообщений
 */
export const readMessagesActiveChat = (ids: string[]) => {
  resetIsScrollTop(true);
  ids.forEach((idMessage: string) => {
    dispatchMessages({
      type: ActionTypesMessages.READ_MESSAGES,
      payload: idMessage,
    });
  });
};

/**
 * Изменение статуса задачи
 * @param status новый статус задачи
 * @param assignmentId id изменяемой задачи
 * @param actor исполнитель задачи
 */
export const changeTaskStatus = (status?: string, assignmentId?: string, actor?: string) => {
  resetIsScrollTop(true);
  dispatchMessages({
    type: ActionTypesMessages.CHANGE_TASK_STATUS,
    payload: { status, assignmentId, actor },
  });
};
