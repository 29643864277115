import { Button, styled as styledMui, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from 'src/theme';
import { Stack } from '@mui/system';

type TaskStatusProps = {
  module: number;
  actor?: string;
};

type TaskMessageProps = {
  inline?: number;
  component?: React.ElementType;
  prewrap?: number;
  fontSize?: string;
};

const Status = styledMui(Typography)((props: TaskStatusProps) => ({
  fontWeight: props.actor ? 500 : 700,
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: props.module ? 'left' : 'right',
}));

const TaskStatusMobile = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

const ButtonTaskResolve = styledMui(Button)`
padding: 6px 13px;
font-weight: 500;
font-size: 14px;
line-height: 18px;
cursor: pointer;
z-index: 1;
`;

const ButtonTaskReject = styledMui(Button)`
padding: 6px 18px;
font-weight: 500;
font-size: 14px;
line-height: 18px;
cursor: pointer;
z-index: 1;
`;

const TaskMessage = styledMui(Typography)((props: TaskMessageProps) => ({
  fontSize: props.fontSize || '14px',
  lineHeight: '20px',
  color: theme.palette.grey[700],
  whiteSpace: props.prewrap ? 'pre-wrap' : 'normal',
  display: props.inline ? 'inline-block' : 'block',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));

const TaskMessageInline = styledMui(Stack)((props: TaskMessageProps) => ({
  fontSize: props.fontSize || '14px',
  lineHeight: '20px',
  flexDirection: 'row',
  whiteSpace: props.prewrap ? 'pre-wrap' : 'normal',
  gap: '12px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));

export { Status, TaskStatusMobile, ButtonTaskResolve, ButtonTaskReject, TaskMessage, TaskMessageInline };
