import { TMessageItem, TScrollMessageValue, TSendMessage } from './models';
import API from '../api';
import { Pager } from '../common/models';

const PATH_MESSAGES = '/messages';

const postMessage = (message: TSendMessage) => API.post<TMessageItem>(PATH_MESSAGES, message).then(result => result);

const searchMessages = (searchTerm: string, value: Pager) =>
  API.post<TMessageItem[]>(`${PATH_MESSAGES}/search`, { searchTerm: searchTerm, pager: value }).then(result => result);

const scrollMessage = (value: TScrollMessageValue) =>
  API.post<TMessageItem[]>(`${PATH_MESSAGES}/load`, value).then(result => result);

const readMessages = (messages: { ids: string[]; conversationId: string }) =>
  API.post(`${PATH_MESSAGES}/bulk-read`, messages).then(result => result);

const searchMessagesConversation = (searchTerm: string, value: Pager, conversationId: string) =>
  API.post<TMessageItem[]>(`${PATH_MESSAGES}/${conversationId}/search`, { searchTerm: searchTerm, take: value.take, skip: value.skip }).then(result => result);

export { postMessage, scrollMessage, searchMessages, readMessages, searchMessagesConversation };
