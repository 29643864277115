import * as ReactDOM from 'react-dom/client';
import { App, AppProps } from './App';
import { theme } from './theme';
import { CacheProvider } from '@emotion/react';
import createCache, { EmotionCache } from '@emotion/cache';
import { ThemeProvider, CssBaseline } from '@mui/material';

const isDev = process.env.NODE_ENV === 'development';

class Module extends HTMLElement {
  shadow: ShadowRoot;

  styleRoot: HTMLDivElement;

  root: HTMLDivElement;

  app: ReactDOM.Root;

  cache: EmotionCache;

  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.styleRoot = document.createElement('div');
    this.root = document.createElement('div');
    this.styleRoot.dataset.style = '';
    this.root.dataset.app = '';
    this.root.style.flexGrow = '1';
    this.root.style.display = 'flex';
    this.app = ReactDOM.createRoot(this.root);
    this.cache = createCache({
      key: 'css',
      prepend: true,
      container: this.styleRoot,
    });
  }

  connectedCallback() {
    if (this.isConnected) {
      this.mount();
    }
  }

  disconnectedCallback() {
    if (!this.isConnected) {
      this.unmount();
    }
  }

  static get observedAttributes() {
    return [
      'patient-id',
      'patient-name',
      'isUnmount',
      'order-id',
      'lab-code',
      'conversationId',
      'anchor',
      'module',
      'hostingModuleUrl',
      'organizationId',
      'organizationAddressId',
      'orderLinkTemplate',
      'resultLinkTemplate',
    ];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      const keys = name.split('-');
      const key = (keys[0] + `${keys[1]?.charAt(0).toUpperCase()}${keys[1]?.slice(1)}` ||
        '' + keys[1]?.slice(1) ||
        '') as keyof AppProps;
      this.render({
        [key]: newValue,
        patientName: this.patientName,
        patientId: this.patientId,
        isUnmount: null,
        orderId: null,
        labCode: null,
        organizationId: null,
        organizationAddressId: null,
        orderLinkTemplate: null,
        resultLinkTemplate: null,
        conversationId: null,
        anchor: null,
      });
    }
  }

  get patientName() {
    return this.getAttribute('patient-name');
  }

  get patientId() {
    return this.getAttribute('patient-id');
  }

  get orderId() {
    return this.getAttribute('order-id');
  }

  get labCode() {
    return this.getAttribute('lab-code');
  }

  get conversationId() {
    return this.getAttribute('conversationId');
  }

  get anchor() {
    return this.getAttribute('anchor');
  }

  get module() {
    return this.getAttribute('module');
  }

  get hostingModuleUrl() {
    return this.getAttribute('hostingModuleUrl');
  }

  get organizationId() {
    return this.getAttribute('organizationId');
  }

  get organizationAddressId() {
    return this.getAttribute('organizationAddressId');
  }

  get orderLinkTemplate() {
    return this.getAttribute('orderLinkTemplate');
  }

  get resultLinkTemplate() {
    return this.getAttribute('resultLinkTemplate');
  }

  render(props: AppProps) {
    return this.app.render(
      <CacheProvider value={this.cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App {...props} />
        </ThemeProvider>
      </CacheProvider>,
    );
  }

  mount() {
    this.shadow.append(this.styleRoot);
    this.shadow.append(this.root);
    this.render({
      patientName: this.patientName,
      patientId: this.patientId,
      orderId: this.orderId,
      labCode: this.labCode,
      module: isDev ? 'PROFESSIONAL' : this.module,
      hostingModuleUrl: this.hostingModuleUrl,
      organizationId: this.organizationId,
      organizationAddressId: this.organizationAddressId,
      orderLinkTemplate: this.orderLinkTemplate,
      resultLinkTemplate: this.resultLinkTemplate,
      conversationId: this.conversationId,
      anchor: this.anchor,
    });
  }

  unmount() {
    this.root.remove();
    this.styleRoot.remove();
    this.render({
      isUnmount: 'true',
    });
  }
}

window.customElements.define('web-message', Module);
