import React, { createContext } from 'react';
import { useReducer } from 'react';
import { reducer } from './reducer';
import { initialStateChats, StateChats } from './const';

type PropsProvider = {
  children: JSX.Element;
};

export const ChatsContext = createContext<StateChats>(initialStateChats);

export let dispatchChats: React.Dispatch<any> = null as unknown as React.Dispatch<any>;

export const ChatsProvider: React.FC<PropsProvider> = ({ children }) => {
  const [value, dispatch] = useReducer(reducer, initialStateChats);

  React.useMemo(() => (dispatchChats = dispatch), [dispatch]);

  return <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>;
};
