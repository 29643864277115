import React from 'react';
import { Typography } from '@mui/material';
import { MessageItem } from './styled';
import { chatsActions } from 'src/context/chats';
import { Stack, SxProps } from '@mui/system';
import { getDisplayDate } from 'src/utils/dateUtils';
import { TMessageItem } from 'src/api/messages/models';
import Avatar from '../Header/Avatar';
import { messagesActions } from 'src/context/messages';

const classes = {
  box_wrapper: {
    width: '100%',
    padding: '12px 12px 12px 20px',
  } as SxProps,
};

type MessageSearchItemProps = {
  message: TMessageItem;
  selectedMessage: {
    index: number;
    created: string;
  };
  setSelectedMessage: React.Dispatch<
    React.SetStateAction<{
      index: number;
      created: string;
    }>
  >;
  index: number;
};

export const MessageSearchItem = React.forwardRef<HTMLDivElement, MessageSearchItemProps>(
  ({ message, selectedMessage, setSelectedMessage, index }, ref) => {
    const { created, body, author, avatarUrl } = message;
    const date = getDisplayDate(created);
    const handleClick = () => {
      messagesActions.setIsViewFoundMessagesConversation(false);
      chatsActions.setAnchorMessage(created);
      setSelectedMessage(prev => ({ ...prev, index: index, created: created }));
    };

    return (
      <MessageItem
        selected={created === selectedMessage.created}
        onClick={handleClick}
        children={
          <Stack direction="row" sx={classes.box_wrapper} ref={ref} gap="12px">
            <Avatar name={author || ''} src={avatarUrl} />
            <Stack width="calc(100% - 145px)">
              <Typography variant="body18Bold" color={'grey.600'} noWrap lineHeight="24px">
                {author}
              </Typography>
              <Stack justifyContent="space-between" direction="row">
                <Typography
                  variant="label14Medium"
                  color={'grey.500'}
                  lineHeight="20px"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {body}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" alignItems="end">
              <Typography noWrap variant="label14Medium" color="grey.400" lineHeight="20px" sx={{ flexShrink: 0 }}>
                {date}
              </Typography>
            </Stack>
          </Stack>
        }
      />
    );
  },
);
