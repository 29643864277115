export enum OrderStatuses {
  'Draft' = 'Draft',
  'InProgress' = ' InProcess',
  'New' = 'New',
  'InTransit' = 'InTransit',
  'Completed' = 'Completed',
}

export enum CategoriesDiagnoses {
  'All' = 'All',
  'Favorites' = 'Favorites',
  'Common' = 'Common',
  'Recent' = 'Recent',
}

export interface IOrdersResponse {
  ordersFilter: any;
  orderItems: IOrder[];
}

export interface IOrder {
  id: number;
  patientId: number;
  patientFirstName: string;
  patientLastName: string;
  patient: string;
  created: string;
  orderId: string;
  providerId: number;
  providerFirstName: string;
  providerLastName: string;
  provider: string;
  status: OrderStatuses;
  expectedDate: string;
  reportDate: string;
}
export interface IProduct {
  id: string;
  name: string;
  code: string;
  cptCode: string;
  loincCode: string;
  synonym: string;
  specimenName: string;
  labs: {
    code: string;
    name: string;
  }[];
  characteristics: {
    classes: string[];
  };
  panelProducts: IProduct[];
  isFavorite: boolean;
}
export interface ITestResponse {
  testsFilter: any;
  productItems: IProduct[];
}

export type Diagnoses = {
  code: string;
  name: string;
  description?: string;
  esbId: string;
};

export type UserItems = {
  firstName: string;
  id: number;
  lastName: string;
};

export type TOrder = {
  id: string;
  productsSection: { labCode: string; providerId: string; billingType: string; providerName: string };
  patientSection: {
    patientForm: {
      firstName: string;
      lastName: string;
      birthDate: string;
      patientIdentifier: string;
      patientId: string;
    };
  };
};

export type StandardFilter = {
  search: string;
  page?: IPageFilter;
};
