import { TMessageItem } from 'src/api/messages/models';

export const initialStateMessages: StateMessages = {
  messages: [],
  searchMessages: [],
  searchMessagesConversation: [],
  isFetching: false,
  lengthMessageSearch: 0,
  isScrollTop: false,
  isEndTopMessage: false,
  isEndBottomMessage: false,
  firstLoading: false,
  isSentMessage: false,
  isEndOfAllSearchMessages: false,
  isEndOfAllSearchMessagesConversation: false,
  isViewFoundMessagesConversation: false,
};

export type StateMessages = {
  /** Массив сообщений */
  messages: TMessageItem[];
  /** Массив сообщений в результате поиска */
  searchMessages: TMessageItem[];
  /** Массив сообщений в результате поиска в чате */
  searchMessagesConversation: TMessageItem[];
  /** Флаг загрузки сообщений */
  isFetching: boolean;
  /** Количество сообщений при загрузке, нужен для скролла к середине сообщения */
  lengthMessageSearch: number;
  /** Флаг прокрутки сообщений наверх, нужен чтобы не скроллить к последнему сообщению при каждой загрузке */
  isScrollTop: boolean;
  /** Конец сообщений сврху */
  isEndTopMessage: boolean;
  /** Конец сообщений снизу */
  isEndBottomMessage: boolean;
  /** Флаг первой загрузки сообщений */
  firstLoading: boolean;
  /** Флаг отправленного сообщения для сброса чата вниз */
  isSentMessage: boolean;
  /** Флаг окончания списка сообщений при поиске */
  isEndOfAllSearchMessages: boolean;
  /** Флаг окончания списка сообщений при поиске в чате */
  isEndOfAllSearchMessagesConversation: boolean;
  /** Флаг активации поиска сообщений в чате */
  isViewFoundMessagesConversation: boolean;
};

export enum ActionTypesMessages {
  SET_FETCHING = 'SET_FETCHING',
  GET_CHAT_MESSAGE = 'GET_CHAT_MESSAGE',
  /** Получение сообщения по очереди */
  GET_MESSAGE_QUEUE = 'GET_MESSAGE_QUEUE',
  SET_IS_SCROLL_TOP = 'SET_IS_SCROLL_TOP',
  /** Прочтение массива сообщений */
  READ_MESSAGES = 'READ_MESSAGES',
  /** Установка длинны массива сообщений (для скролла к середине) */
  SET_LENGTH_MESSAGE_SEARCH = 'SET_LENGTH_MESSAGE_SEARCH',
  SET_END_TOP_MESSAGES = 'SET_END_TOP_MESSAGES',
  SET_END_BOTTOM_MESSAGES = 'SET_END_BOTTOM_MESSAGES',
   /** Установка конца списка сообщений при поиске */
   SET_END_SEARCH_MESSAGES = 'SET_END_SEARCH_MESSAGES',
   /** Добавление сообщений при поиске в конец массива */
   ADD_SEARCH_MESSAGES_BOTTOM = 'ADD_SEARCH_MESSAGES_BOTTOM',
   /** Очищение списка сообщений поиска */
   SET_SEARCH_MESSAGES_INITIAL = 'SET_SEARCH_MESSAGES_INITIAL',
   /** Установка конца списка сообщений при поиске в чате */
   SET_END_SEARCH_MESSAGES_CONVERSATION = 'SET_END_SEARCH_MESSAGES_CONVERSATION',
   /** Добавление сообщений при поиске в чате в конец массива */
   ADD_SEARCH_MESSAGES_CONVERSATION_BOTTOM = 'ADD_SEARCH_MESSAGES_CONVERSATION_BOTTOM',
   /** Очищение списка сообщений в чате поиска */
   SET_SEARCH_MESSAGES_CONVERSATION_INITIAL = 'SET_SEARCH_MESSAGES_CONVERSATION_INITIAL',
   /** Открытие или скрытие найденных сообщений */
  SET_IS_VIEW_FOUND_MESSAGES_CONVERSATION = 'SET_IS_VIEW_FOUND_MESSAGES_CONVERSATION',
  /** Инциализация массива сообщений  */
  SET_MESSAGES = 'SET_MESSAGES',
  /** Добавление сообщений вниз массива сообщений  */
  ADD_MESSAGES_BOTTOM = 'ADD_MESSAGES_BOTTOM',
  /** Добавление сообщений в топ массива сообщений  */
  ADD_MESSAGES_TOP = 'ADD_MESSAGES_TOP',
  SET_LOADING_FOR_PLELOADER = 'SET_LOADING_FOR_PLELOADER',
  /** Установка флага отправленного сообщения пользователем (нужен для сброса чата вниз при отправленном сообщении) */
  SET_IS_SENT_MESSAGE = 'SET_IS_SENT_MESSAGE',
  /** Изменение статуса задачи */
  CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS',
}
