import { forwardRef } from 'react';
import { SystemBody, SystemMessageWrapper } from './styled';

type SystemMessageProps = {
  text?: string;
};
type Ref = HTMLDivElement;

export const SystemMessage = forwardRef<Ref, SystemMessageProps>(({ text }, ref) => {
  return (
    <SystemMessageWrapper ref={ref}>
      <SystemBody>{text}</SystemBody>
    </SystemMessageWrapper>
  );
});

SystemMessage.defaultProps = {
  text: ''
}
