import API from '../apiPortal';
import { IOrdersResponse, ITestResponse, Diagnoses, UserItems, TOrder, StandardFilter } from './models';

const getOrders = (filter: any) => API.post<IOrdersResponse>('/orders/search', filter);

const getTests = (orderId: string, providerId: string) =>
  API.get<ITestResponse>(`/orders/${orderId}/products-for-add?providerId=${providerId}`);

const getSelectableDiagnoses = (filter: StandardFilter & { selectable?: boolean; parentDiagnosisEsbId?: string }) =>
  API.post<{ diagnosesForms: Diagnoses[] }>('/references/diagnoses/search', { ...filter, selectable: true }).then(
    res => res.data.diagnosesForms,
  );

const getUnselectableDiagnoses = (filter: StandardFilter & { selectable?: boolean; parentDiagnosisEsbId?: string }) =>
  API.post<{ diagnosesForms: Diagnoses[] }>('/references/diagnoses/search', { ...filter, selectable: false }).then(
    res => res.data.diagnosesForms,
  );

const getСommonDiagnoses = (filter: StandardFilter & { orderId?: string }) =>
  API.post<Diagnoses[]>('/references/common-diagnoses/search', filter).then(res => res.data);

const getFavoritesDiagnoses = (filter: StandardFilter) =>
  API.post<{ favoriteDiagnoses: Diagnoses[] }>('/favorite_diagnoses/by_filter', filter).then(
    res => res.data.favoriteDiagnoses,
  );

const getRecentDiagnoses = ({ patientId, ...filter }: StandardFilter & { patientId?: string }) =>
  API.post<Diagnoses[]>(`/patients/${patientId}/recent_diagnoses/search`, filter).then(res => res.data);

const getProvidersByLab = (filter: any) =>
  API.post<UserItems[]>('/provider-maintenances/fn/order-providers', { ...filter });

const getOrderById = (id: string) => API.get<TOrder>(`/orders/${id}/by-number`);

export {
  getOrders,
  getTests,
  getSelectableDiagnoses,
  getUnselectableDiagnoses,
  getСommonDiagnoses,
  getFavoritesDiagnoses,
  getRecentDiagnoses,
  getProvidersByLab,
  getOrderById,
};
