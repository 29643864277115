import React, { useContext } from 'react';
import { TMessageItem } from 'src/api/messages/models';
import { PortalContext } from 'src/context/portal';
import ReactMarkdown from 'react-markdown';
import { prepareOrderLink, prepareResultLink } from './helpers';

type NoticeOfCriticalValuesProps = {
  task: TMessageItem;
};

const componentParagraph = {
  p: (props: any) => <p style={{ lineHeight: '22px', fontSize: '14px', marginBlock: '0 8px' }}>{props.children}</p>,
  a: (props: any) => (
    <a
      href={props.href}
      style={{
        fontWeight: 500,
        marginLeft: '6px',
      }}
    >
      {props.children}
    </a>
  ),
};

export const NoticeOfCriticalValues: React.FC<NoticeOfCriticalValuesProps> = ({ task }) => {
  const { body, originalOrderNumber, resultId } = task;
  const { orderLinkTemplate, resultLinkTemplate } = useContext(PortalContext);

  const pathForOrderByNumber = prepareOrderLink(orderLinkTemplate, originalOrderNumber);
  const pathForResultReportById = prepareResultLink(resultLinkTemplate, resultId, originalOrderNumber);

  const bodyWithLinks: string = body
    ? body
        .replace(' __order__', `[#${originalOrderNumber}](${pathForOrderByNumber})`)
        .replace(' **__report__**', `[report](${pathForResultReportById})`)
    : '';
  return <ReactMarkdown components={componentParagraph}>{bodyWithLinks}</ReactMarkdown>;
};
