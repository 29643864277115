import { Controller } from 'react-hook-form';
import { FormContolAutocomplete } from '../FormContolAutocomplete';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  Stack,
  Typography,
} from '@mui/material';
import { defaultCategoryDiagnoses } from './DiagnosesInput.contst';
import { CategoriesDiagnoses } from 'src/api/portal/models';
import { CategoryButton, DiagnosesCategory, DiagnosesOptionItem } from './DiagnosesInput.components';
import { hexOpacity } from 'src/utils/hexOpacity';
import { theme } from 'src/theme';
import { DiagnosesLocal, diagnosesFetch } from './DiagnosesInput.helpers';
import { TAKE_PAGER_OPTIONS, border, scrollBar } from 'src/const';
import { getSelectableDiagnoses, getUnselectableDiagnoses } from 'src/api/portal/portal';
import { PreloaderBubbles } from 'src/components/ui';
import { useScroll } from 'src/hooks/useScroll';

interface DiagnosesInputProps {
  control: any;
  disabled: boolean;
  isOpenTestRequestModal: boolean;
  addDiagnoses: (val: string[]) => void;
  patientId: string;
  orderId: string;
  selectedDiagnoses: string[];
}

const PaperComponent = ({
  children,
  isOpenTestRequestModal,
  patientId,
  orderId,
  handleCancel,
  search,
  addDiagnoses,
  selectedDiagnoses,
  ...paperProps
}: PaperProps & {
  isOpenTestRequestModal: boolean;
  patientId: string;
  orderId: string;
  handleCancel: () => void;
  search: string;
  addDiagnoses: (val: string[]) => void;
  selectedDiagnoses: string[];
}) => {
  const [diagnoses, setDiagnoses] = useState<DiagnosesLocal[]>([]);
  const [categoryDiagnoses, setCategoryDiagnoses] = useState<DiagnosesLocal[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState(CategoriesDiagnoses.All);

  const [parentDiagnosisEsbId, setParentDiagnosisEsbId] = useState('');

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCategoryNumber, setPageCategoryNumber] = useState(1);

  const lastItemDiagnosis = useRef<HTMLButtonElement>(null);
  const lastItemCategoryDiagnosis = useRef<HTMLButtonElement>(null);
  const diagnosesContainer = useRef<HTMLButtonElement>(null);

  const [checkedDiagnoses, setCheckedDiagnoses] = useState<string[]>(selectedDiagnoses || []);
  const disabled = checkedDiagnoses.length > 99;
  useEffect(() => {
    if (parentDiagnosisEsbId) {
      const getUnselectableDiagnosesByIsbId = async () => {
        setLoading(true);
        await getSelectableDiagnoses({ search, parentDiagnosisEsbId }).then(res => {
          setDiagnoses(res);
          return setLoading(false);
        });
      };
      void getUnselectableDiagnosesByIsbId();
    }
  }, [parentDiagnosisEsbId, pageNumber, search]);

  useEffect(() => {
    const generalFilter = { search, page: { number: pageNumber, size: TAKE_PAGER_OPTIONS } };
    if (isOpenTestRequestModal) {
      diagnosesFetch({
        activeCategory,
        generalFilter,
        patientId,
        orderId,
        scroll: pageNumber > 1,
        setDiagnoses,
        setLoading,
        setCategoryDiagnoses,
      });
    }
  }, [isOpenTestRequestModal, activeCategory, patientId, orderId, pageNumber, search]);

  const scrollBottom = () => {
    setPageNumber(prev => prev + 1);
  };

  const scrollCategotyBottom = async () => {
    await getUnselectableDiagnoses({ search, page: { number: pageCategoryNumber + 1, size: TAKE_PAGER_OPTIONS } })
      .then(result => {
        const newDiagnoses = result.map(({ code, name, esbId }) => ({
          name,
          code,
          esbId,
        }));
        return setCategoryDiagnoses(prev => [...prev, ...newDiagnoses]);
      })
      .catch(() => {});
    setPageCategoryNumber(prev => prev + 1);
  };

  useScroll(lastItemDiagnosis, scrollBottom, loading || diagnoses.length < TAKE_PAGER_OPTIONS);
  useScroll(lastItemCategoryDiagnosis, scrollCategotyBottom, loading || categoryDiagnoses.length < TAKE_PAGER_OPTIONS);

  const handleCategoryClick = (value: CategoriesDiagnoses) => {
    if (diagnosesContainer.current) diagnosesContainer.current.scrollTop = 0;
    setPageNumber(1);
    setPageCategoryNumber(1);
    setCategoryDiagnoses([]);
    setActiveCategory(value);
  };

  const onApply = () => {
    addDiagnoses(checkedDiagnoses);
    handleCancel();
  };

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <Paper
        sx={{
          boxShadow: '0px 8px 12px rgba(55, 65, 81, 0.1), 0px 0px 6px rgba(55, 65, 81, 0.1)',
          height: '100%',
        }}
        {...paperProps}
      >
        <Stack
          direction="row"
          gap="12px"
          padding="10px 16px"
          borderBottom={`1px solid ${hexOpacity(theme.palette.secondary.main, '14%')}`}
        >
          {defaultCategoryDiagnoses.map(({ displayName, value }) => (
            <CategoryButton
              key={value}
              onClick={() => handleCategoryClick(value)}
              selected={+(activeCategory === value)}
            >
              {displayName}
            </CategoryButton>
          ))}
        </Stack>
        <Stack height="calc(100% - 104px)" direction="row">
          {categoryDiagnoses.length > 0 && (
            <Stack width="50%" borderRight={border} mt="12px" overflow="auto" sx={scrollBar}>
              {categoryDiagnoses.map((diagnosis, index, arr) => (
                <DiagnosesCategory
                  lastItemCategoryDiagnosis={index === arr.length - 2 ? lastItemCategoryDiagnosis : null}
                  key={diagnosis.esbId}
                  diagnosis={diagnosis}
                  setParentDiagnosisEsbId={setParentDiagnosisEsbId}
                  level={1}
                  parentDiagnosisEsbId={parentDiagnosisEsbId}
                />
              ))}
            </Stack>
          )}
          <Stack
            ref={diagnosesContainer}
            width={categoryDiagnoses.length > 0 ? '50%' : '100%'}
            position="relative"
            overflow="auto"
            height="100%"
            sx={scrollBar}
          >
            {loading && <PreloaderBubbles position="sticky" />}
            {diagnoses.length > 0 ? (
              diagnoses.map((diagnosis, index, arr) => {
                const labelDiagnosis = `${diagnosis.code} ${diagnosis.name}`;
                const checked = checkedDiagnoses.includes(labelDiagnosis);
                const onClickDiagnosis = () => {
                  if (!checked) {
                    setCheckedDiagnoses(prev => [...new Set([...prev, labelDiagnosis])]);
                  } else {
                    setCheckedDiagnoses(prev => prev.filter(di => di !== labelDiagnosis));
                  }
                };
                const localDisabled = disabled && !checked;
                return (
                  <DiagnosesOptionItem
                    disabled={localDisabled}
                    ref={index === arr.length - 2 ? lastItemDiagnosis : null}
                    diagnosis={diagnosis}
                    checked={checked}
                    onClick={onClickDiagnosis}
                    key={diagnosis.esbId}
                  />
                );
              })
            ) : (
              <Box padding="24px">
                <Typography color="grey.500" variant="label14Medium">
                  Diagnosis not found. Try to change search request.
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          padding="12px 16px"
          direction="row"
          justifyContent="space-between"
          position="absolute"
          bottom="0"
          width="100%"
          borderTop={`1px solid ${hexOpacity(theme.palette.secondary.main, '14%')}`}
          zIndex={20}
          bgcolor="#FFFFFF"
        >
          <Button
            disabled={false}
            color="secondary"
            size="small"
            variant="outlined"
            sx={{ width: '78px', heigth: '30px', fontWeight: 500, fontSize: '14px' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={false}
            color="secondary"
            variant="contained"
            size="small"
            sx={{ width: '78px', heigth: '30px', fontWeight: 500, fontSize: '14px' }}
            onClick={onApply}
          >
            Apply
          </Button>
        </Stack>
      </Paper>
    </ClickAwayListener>
  );
};

export const DiagnosesInput: FC<DiagnosesInputProps> = ({
  control,
  disabled,
  isOpenTestRequestModal,
  addDiagnoses,
  patientId,
  orderId,
  selectedDiagnoses,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleCancel = () => {
    setOpen(false);
    setSearch('');
  };

  const customPopper = useMemo(
    () =>
      ({ children, ...popperProps }: PopperProps) => {
        const windowHeight = document.documentElement.clientHeight - 60;
        const popperPosition = (popperProps.anchorEl as any).getBoundingClientRect().top;
        const heightPoper = windowHeight - popperPosition;
        return (
          <Popper
            sx={{
              height: `${heightPoper}px`,
              boxShadow: '0px 8px 12px rgba(55, 65, 81, 0.1), 0px 0px 6px rgba(55, 65, 81, 0.1)',
            }}
            {...popperProps}
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
            ]}
            placement="bottom"
          >
            {children}
          </Popper>
        );
      },
    [],
  );

  return (
    <Controller
      name="diagnoses"
      control={control}
      render={() => (
        <FormContolAutocomplete
          name="diagnoses"
          control={control}
          renderOption={() => <></>}
          open={open}
          variant="outlined"
          disabled={disabled}
          disableClearable
          onClose={() => {}}
          PaperComponent={(props: PaperProps) => (
            <PaperComponent
              {...props}
              isOpenTestRequestModal={isOpenTestRequestModal}
              patientId={patientId}
              orderId={orderId}
              handleCancel={handleCancel}
              search={search}
              addDiagnoses={addDiagnoses}
              selectedDiagnoses={selectedDiagnoses}
            />
          )}
          PopperComponent={customPopper}
          setState={{}}
          onClick={() => (disabled ? {} : setOpen(prev => !prev))}
          onSearch={(searchValue: { search: string }) => {
            setSearch(searchValue.search);
          }}
          placeholder="Select"
          label="Diagnoses"
          options={[]}
        />
      )}
    />
  );
};
