import styled from '@emotion/styled';
import { Button, styled as styledMui } from '@mui/material';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH, border } from 'src/const';
import { theme } from 'src/theme';

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${theme.palette.grey[800]};
  margin-left: 16px;
`;

const Role = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #999fab;
  margin-left: 16px;
`;

const HeaderWrapper = styled.div`
  border-bottom: ${border};
  padding: 24.5px 36px;
  display: flex;
  justify-content: space-between;
  height: 92px;
  @media (max-width: ${MAX_MOBILE_WIDTH}px) {
    padding: 22px 24px;
    height: 84px;
  }
  @media (max-width: 370px) {
    padding: 22px 4px;
    height: 84px;
  }
`;

const CreateTaskButton = styledMui(Button)`
font-weight: 500;
font-size: 14px;
line-height: 20px;
margin-right: 12px;
padding: 11px 32px;
@media (max-width: ${MAX_TABLET_WIDTH}px) {
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
}
`;

const AddOnTestsRequestButton = styledMui(Button)`
font-weight: 500;
font-size: 14px;
line-height: 20px;
padding: 11px 32px;
@media (max-width: ${MAX_TABLET_WIDTH}px) {
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
}
`;

const PatientChartButton = styledMui(Button)`
font-weight: 500;
font-size: 14px;
line-height: 20px;
padding: 11px 32px;
@media (max-width: ${MAX_TABLET_WIDTH}px)  {
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
}
`;

const SearchButton = styledMui(Button)`
padding: 12px;
width: 42px;
min-width: 42px;
margin-left: 12px;
`;

const CloseSearchButton = styledMui(Button)`
padding: 9px;
width: 42px;
height: 42px;
min-width: 42px;
`;

export { Name, Role, HeaderWrapper, CreateTaskButton, AddOnTestsRequestButton, PatientChartButton, SearchButton, CloseSearchButton };
