export type Pager = {
  take: number;
  skip: number;
};

export enum ModuleType {
  Professional = 'PROFESSIONAL',
  Laboratory = 'LIS',
  Patient = 'PATIENT',
}

export enum ChatType {
  General = 'General',
  DoctorPatient = 'DoctorPatient',
  Laboratory = 'Laboratory',
}
