import { SearchType, TChatItem } from 'src/api/chats/models';
import { chatsActions } from 'src/context/chats';

export const setNeededChat = (
  chats: TChatItem[],
  patientId?: Nullable<string>,
  labCode?: Nullable<string>,
  conversationId?: Nullable<string>,
) => {
  chatsActions?.setActiveChatLabCode('');
  if (patientId) {
    const chatWithThisPatient = chats.find(chat => chat.patientId === patientId);
    if (chatWithThisPatient) {
      chatsActions?.setActiveChat(chatWithThisPatient.id);
      chatsActions?.setCreatedActiveChat(chatWithThisPatient.lastMessage?.created);
    } else {
      void chatsActions.addChatToTop(patientId, SearchType.ByPatientId);
    }
  }
  if (labCode) {
    const chatWithThisLab = chats.find(chat => chat.laboratoryCode === labCode);
    if (chatWithThisLab) {
      chatsActions?.setActiveChat(chatWithThisLab.id);
      chatsActions?.setCreatedActiveChat(chatWithThisLab.lastMessage?.created);
      chatsActions?.setActiveChatLabCode(labCode);
    } else {
      void chatsActions.addChatToTop(labCode, SearchType.ByLabcode);
      chatsActions?.setActiveChatLabCode(labCode);
    }
  }
  if (conversationId) {
    const thisChat = chats.find(chat => chat.id === conversationId);
    if (thisChat) {
      chatsActions?.setActiveChat(conversationId);
      chatsActions?.setCreatedActiveChat(thisChat.lastMessage?.created);
      chatsActions?.setActiveChatLabCode(thisChat.laboratoryCode);
    } else {
      void chatsActions.addChatToTop(conversationId, SearchType.ByConversationId);
    }
  }
};
