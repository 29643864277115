import { Dialog, Typography, Stack, IconButton, Button, SxProps, Divider, Box, styled } from '@mui/material';
import { ReactComponent as CloseIcon } from 'src/icons/close-lg.svg';
import React from 'react';

type ModalProps = {
  open: boolean;
  onClose(): void;
  onCrossClick?(): void;
  title: string;
  body: string | JSX.Element;
  onAccept(): void;
  sx?: SxProps;
  loading?: boolean;
};

const classes = {
  button: {
    minWidth: 120,
  } as SxProps,
};

const BoxWrapper = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: theme.shape.borderRadius,
  padding: '36px',
}));

export const ModalConfirm: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  body,
  onAccept,
  sx,
  loading,
  onCrossClick,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCrossClick || onClose}
      PaperComponent={BoxWrapper}
      PaperProps={{ sx: { maxWidth: '400px', minWidth: '400px', padding: 0, ...sx } }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" padding="3px 12px 3px 24px">
        <Typography variant="body18Bold" component="h2" color="grey.700">
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box padding="24px">
        {typeof body === 'string' ? (
          <Typography variant="body16Medium" color="grey.700" lineHeight="24px">
            {body}
          </Typography>
        ) : (
          body
        )}
      </Box>
      <Stack direction="row" justifyContent="space-between" gap="40px" padding="24px">
        <Button variant="outlined" color="secondary" sx={classes.button} onClick={onClose}>
          No
        </Button>
        <Button variant="contained" sx={classes.button} onClick={onAccept} color="secondary" disabled={loading}>
          Yes
        </Button>
      </Stack>
    </Dialog>
  );
};
