import { Stack } from '@mui/material';
import { FC } from 'react';
import { Main } from './components';
import { ChatsProvider } from './context/chats';
import { MessagesProvider } from './context/messages';
import { PortalProvider } from './context/portal';
import { ProfileProvider } from './context/profile';
import { SearchValueProvider } from './context/searchValue';
import './index.css';

export interface AppProps {
  patientName?: Nullable<string>;
  patientId?: Nullable<string>;
  isUnmount?: Nullable<string>;
  orderId?: Nullable<string>;
  labCode?: Nullable<string>;
  module?: Nullable<string>;
  hostingModuleUrl?: Nullable<string>;
  organizationId?: Nullable<string>;
  organizationAddressId?: Nullable<string>;
  orderLinkTemplate?: Nullable<string>;
  resultLinkTemplate?: Nullable<string>;
  conversationId?: Nullable<string>;
  anchor?: Nullable<string>;
}

export const App: FC<AppProps> = ({
  patientName,
  patientId,
  isUnmount,
  orderId,
  labCode,
  conversationId,
  anchor,
  module,
  hostingModuleUrl,
  organizationId,
  organizationAddressId,
  orderLinkTemplate,
  resultLinkTemplate,
}) => {
  return (
    <PortalProvider
      value={{
        patientName,
        patientId,
        isUnmount,
        orderId,
        labCode,
        conversationId,
        anchor,
        module,
        hostingModuleUrl,
        organizationId,
        organizationAddressId,
        orderLinkTemplate,
        resultLinkTemplate,
      }}
    >
      <ProfileProvider>
        <ChatsProvider>
          <SearchValueProvider>
            <MessagesProvider>
              <Stack
                sx={{
                  flexGrow: 1,
                  width: '100%',
                }}
              >
                <Main />
              </Stack>
            </MessagesProvider>
          </SearchValueProvider>
        </ChatsProvider>
      </ProfileProvider>
    </PortalProvider>
  );
};
