import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material';
import { ReactComponent as UnCheckedIcon } from './uncheck.svg';
import { ReactComponent as CheckedIcon } from './check.svg';

export const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      icon={<UnCheckedIcon />}
      checkedIcon={<CheckedIcon style={{ fill: props?.disabled ? '#C7CDD6' : '#19A1B7' }} />}
      {...props}
    />
  );
};
