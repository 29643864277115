import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from 'src/icons/close-lg.svg';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { ModalWrapper, ModalWrapperProps } from './ModalWrapper';

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
  sx?: SxProps;
  icon?: React.ReactNode;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, sx, icon } = props;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        m: 0,
        borderBottom: '1px solid #DFF2F5',
        padding: !onClose ? '12px 24px' : '4px 12px 4px 24px',
        ...sx,
      }}
    >
      <Stack direction="row" gap="12px">
        {icon}
        <Typography variant="body18Bold">{children}</Typography>
      </Stack>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Stack>
  );
}

export interface ModalProps {
  open: boolean;
  onCancel?: () => void;
  title: string;
  children: React.ReactNode;
  sxTitle?: SxProps;
  wrapperProps?: Omit<ModalWrapperProps, 'open' | 'onClose' | 'children'>;
  bodySx?: SxProps;
  iconTitle?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  onCancel,
  open,
  title,
  children,
  wrapperProps,
  sxTitle,
  iconTitle,
  bodySx,
}) => {
  return (
    <ModalWrapper onClose={onCancel} open={open} {...wrapperProps} disablePortal>
      <BootstrapDialogTitle onClose={onCancel} sx={sxTitle} icon={iconTitle}>
        {title}
      </BootstrapDialogTitle>
      <Box sx={{ padding: '24px', height: '100%', ...bodySx }}>{children}</Box>
    </ModalWrapper>
  );
};
