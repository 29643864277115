import React, { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { TaskStatusMobile, ButtonTaskResolve, ButtonTaskReject, TaskMessage } from './Task.styled';
import { updateStatus } from 'src/api/assignments/assignments';
import { TAssignment, AssignmentStatus } from 'src/api/assignments/models';
import { TMessageItem } from 'src/api/messages/models';
import { ChatsContext } from 'src/context/chats';
import { RejectionReasonSolving, NoticeOfCriticalValues, AddOnTests, MakeAnAppointment } from './TaskTypes';

type TaskProps = {
  task: TMessageItem;
  isTablet?: boolean;
  isMobile?: boolean;
};

enum TaskStatuses {
  'Incomplete' = 'Unresolved',
  'Completed' = 'Resolved',
  'Cancelled' = 'Reject',
}

export const TasksFactory: React.FC<TaskProps> = ({ task, isMobile }) => {
  const { status, assignmentId, assignmentType, fromCurrentModule, title } = task;
  const { activeChat } = useContext(ChatsContext);

  const hanldeResolvedClick = () => {
    void updateStatus({
      assignmentId: assignmentId as string,
      conversationId: activeChat,
      status: AssignmentStatus.Completed,
    });
  };
  const hanldeRejectedClick = () => {
    void updateStatus({
      assignmentId: assignmentId as string,
      conversationId: activeChat,
      status: AssignmentStatus.Cancelled,
    });
  };
  TAssignment.MakeAnAppointment;

  const renderTask = () => {
    switch (assignmentType) {
      case TAssignment.AddonTests: {
        return <AddOnTests task={task} />;
      }
      case TAssignment.NoticeOfCriticalValues: {
        return <NoticeOfCriticalValues task={task} />;
      }
      case TAssignment.RejectionReasonSolving: {
        return <RejectionReasonSolving task={task} />;
      }
      case TAssignment.MakeAnAppointment: {
        return <MakeAnAppointment task={task} />;
      }
      default: {
        return null;
      }
    }
  };
  return (
    <>
      {isMobile ? (
        <Stack direction="row" justifyContent="space-between">
          <TaskMessage prewrap={1} fontWeight={700} inline={1}>
            {title}
          </TaskMessage>
          <TaskStatusMobile>{status && TaskStatuses[status]}</TaskStatusMobile>
        </Stack>
      ) : (
        <TaskMessage
          prewrap={1}
          fontWeight={700}
          inline={assignmentType === TAssignment.AddonTests ? 1 : 0}
          marginBottom={assignmentType === TAssignment.AddonTests ? '12px' : '4px'}
          fontSize="16px"
          color="grey.700"
        >
          {title}
        </TaskMessage>
      )}
      {renderTask()}
      {!fromCurrentModule && status === (AssignmentStatus.Incomplete as unknown as string) && (
        <Box sx={{ height: 0, order: -1 }} mt="4px" marginBottom="12px">
          <Stack direction="row" gap="12px">
            <ButtonTaskResolve
              size="small"
              color="secondary"
              variant="contained"
              onClick={hanldeResolvedClick}
              disableRipple
            >
              Resolve
            </ButtonTaskResolve>
            {assignmentType !== TAssignment.NoticeOfCriticalValues && (
              <ButtonTaskReject
                disableRipple
                size="small"
                color="secondary"
                variant="outlined"
                onClick={hanldeRejectedClick}
              >
                Reject
              </ButtonTaskReject>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
