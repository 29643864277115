import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { TMessageItem } from 'src/api/messages/models';
import { PortalContext } from 'src/context/portal';
import { prepareOrderLink } from './helpers';

type RejectionReasonSolvingProps = {
  task: TMessageItem;
};

const componentParagraph = {
  p: (props: any) => <p style={{ lineHeight: '22px', marginBlock: '0 8px', fontSize: '14px' }}>{props.children}</p>,
  a: (props: any) => (
    <a href={props.href} style={{ fontWeight: 500, margin: '0 6px' }}>
      {props.children}
    </a>
  ),
};

export const RejectionReasonSolving: React.FC<RejectionReasonSolvingProps> = ({ task }) => {
  const { body, originalOrderNumber } = task;
  const { orderLinkTemplate } = useContext(PortalContext);

  const pathForOrder = prepareOrderLink(orderLinkTemplate, originalOrderNumber);

  const bodyWithLinks: string = body ? body.replace(' __order__ ', `[${originalOrderNumber}](${pathForOrder})`) : '';

  return <ReactMarkdown components={componentParagraph}>{bodyWithLinks}</ReactMarkdown>;
};
