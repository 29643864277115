import React from 'react';
import { AvatarWrapper } from '../styled';

type AvatarProps = {
  name?: Nullable<string>;
  src?: string;
};

const Avatar: React.FC<AvatarProps> = ({ name, src }) => {
  const initials = name
    ? name
        .split(' ')
        .map(word => word[0])
        .splice(0, 2)
        .join('')
    : '';

  return <AvatarWrapper src={src}>{src ? null : initials}</AvatarWrapper>;
};

export default Avatar;
