import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { ChatListItem } from '../ChatListItem';
import { ChatsListMui } from './styled';
import { ChatsContext, chatsActions } from 'src/context/chats';
import { SearchValueContext } from 'src/context/searchValue';
import { Pager } from 'src/api/common/models';
import { useObserver } from 'src/hooks/useObserver';
import { TAKE_PAGER_CHATS, TAKE_PAGER_MESSAGE, scrollBar } from 'src/const';
import { PulseLoader } from 'react-spinners';
import { MessagesContext, messagesActions } from 'src/context/messages';
import { MessageListItem } from '../MessageListItem';

const classes = {
  box_wrapper: {
    flexGrow: 1,
    overflow: 'auto',
    ...scrollBar,
  } as SxProps,
};

export const ChatList: React.FC = () => {
  const { chats, searchChats, isEndOfAllChats, isFetching, isEndOfAllSearchChats, unreadOnly, firstLoading } =
    useContext(ChatsContext);

  const { searchMessages, isFetching: isFetchingMessage, isEndOfAllSearchMessages } = useContext(MessagesContext);
  const { searchValue } = useContext(SearchValueContext);
  const [skipChats, setSkipChats] = useState<number>(TAKE_PAGER_CHATS);
  const [skipSearchChats, setSkipSearchChats] = useState<number>(TAKE_PAGER_CHATS);
  const [skipSearchMessages, setSkipSearchMessages] = useState<number>(TAKE_PAGER_MESSAGE);
  const lastItemChat = React.createRef<HTMLDivElement>();
  const lastItemMessage = React.createRef<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>(null);

  const scrollBottomChats = (entries: IntersectionObserverEntry[]) => {
    if (!searchValue) {
      const valueBottom: Pager = {
        take: TAKE_PAGER_CHATS,
        skip: skipChats,
      };
      if (entries[0].isIntersecting && chats.length >= TAKE_PAGER_CHATS && !isEndOfAllChats && !isFetching) {
        void chatsActions?.scrollChat(valueBottom, unreadOnly);
        setSkipChats(prev => prev + TAKE_PAGER_CHATS);
      }
    } else {
      const valueBottomChats: Pager = {
        take: TAKE_PAGER_CHATS,
        skip: skipSearchChats,
      };
      const valueBottomMessages: Pager = {
        take: TAKE_PAGER_MESSAGE,
        skip: skipSearchMessages,
      };
      if (
        entries[0].isIntersecting &&
        searchChats.length >= TAKE_PAGER_CHATS &&
        !isEndOfAllSearchChats &&
        !isFetching
      ) {
        void chatsActions?.searchChat(searchValue, valueBottomChats);
        void messagesActions?.searchMessage(searchValue, valueBottomMessages);
        setSkipSearchChats(prev => prev + TAKE_PAGER_CHATS);
        setSkipSearchMessages(prev => prev + TAKE_PAGER_MESSAGE);
      }
    }
  };
  useObserver(scrollBottomChats, lastItemChat);

  const scrollBottomMessages = (entries: IntersectionObserverEntry[]) => {
    if (searchValue) {
      const valueBottomMessages: Pager = {
        take: TAKE_PAGER_MESSAGE,
        skip: skipSearchMessages,
      };
      if (
        entries[0].isIntersecting &&
        searchMessages.length >= TAKE_PAGER_MESSAGE &&
        !isEndOfAllSearchMessages &&
        !isFetchingMessage
      ) {
        void messagesActions?.searchMessage(searchValue, valueBottomMessages);
        setSkipSearchMessages(prev => prev + TAKE_PAGER_MESSAGE);
      }
    }
  };
  useObserver(scrollBottomMessages, lastItemMessage);

  useEffect(() => {
    chatsActions?.resetLoadingAllChats();
    setSkipChats(TAKE_PAGER_CHATS);
    setSkipSearchChats(TAKE_PAGER_CHATS);
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
    if (searchValue) {
      messagesActions?.resetSearchMessage();
      void chatsActions?.searchChat(searchValue, { take: TAKE_PAGER_CHATS, skip: 0 });
      void messagesActions?.searchMessage(searchValue, { take: TAKE_PAGER_MESSAGE, skip: 0 });
    }
    if (!searchValue) {
      chatsActions?.resetSearchChat([]);
      messagesActions?.resetSearchMessage();
      void chatsActions.getChatsAsync({ take: TAKE_PAGER_CHATS, skip: 0 }, unreadOnly);
    }
  }, [searchValue, unreadOnly]);

  return (
    <Box ref={ref} sx={classes.box_wrapper}>
      {!firstLoading ? (
        <ChatsListMui>
          {searchChats.length === 0 && searchMessages.length === 0 && searchValue ? (
            <Typography variant="label14Medium" m="12px 24px" lineHeight="18px" display="block" color="grey.400">
              No chats or messages found
            </Typography>
          ) : (
            searchChats.length > 0 && (
              <Stack sx={{ background: '#F3F4F6', height: '26px' }}>
                <Typography variant="label14Medium" ml="24px" mt="4px" mb="4px" lineHeight="18px">
                  Chats
                </Typography>
              </Stack>
            )
          )}
          {(searchValue ? searchChats : chats)?.map((chat, index, array) => (
            <ChatListItem
              chat={chat}
              key={`${chat.id}${chat.lastMessage?.id || chat.updated}`}
              ref={index === array.length - 1 ? lastItemChat : null}
            />
          ))}
          {searchMessages.length > 0 && (
            <Stack sx={{ background: '#F3F4F6', height: '26px' }}>
              <Typography variant="label14Medium" ml="24px" mt="4px" mb="4px" lineHeight="18px">
                Messages
              </Typography>
            </Stack>
          )}
          {(searchValue ? searchMessages : [])?.map((message, index, array) => (
            <MessageListItem
              message={message}
              key={`${message?.messageLink}`}
              ref={index === array.length - 1 ? lastItemMessage : null}
            />
          ))}
        </ChatsListMui>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{
            '@keyframes react-spinners-PulseLoader-pulse': {
              '0%': {
                transform: 'scale(1)',
                opacity: 1,
              },
              '45%': {
                transform: 'scale(0.1)',
                opacity: 0.7,
              },
              '80%': {
                transform: 'scale(1)',
                opacity: 1,
              },
            },
          }}
        >
          <PulseLoader color="#0096b2" size={20} />
        </Stack>
      )}
    </Box>
  );
};
