import React, { useState, useEffect } from 'react';
import WithContext from 'src/api/interceptors';
import { PortalContext, TPortalContextValue } from './context';

type PropsProvider = {
  children: JSX.Element;
  value: TPortalContextValue;
};

type TPortalAction = null | {
  resetParams: () => void;
  setIsNewPatient: () => void;
};

export let portalActions: TPortalAction = null;

export const PortalProvider: React.FC<PropsProvider> = ({ children, value }) => {
  const [patientId, setPatientId] = useState<string>(value?.patientId || '');
  const [patientName, setPatientName] = useState<string>(value?.patientId || '');
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>(value?.orderId || '');
  const [labCode, setLabCode] = useState<string>(value?.labCode || '');
  const [anchor, setAnchor] = useState<string>(value?.anchor || '');
  const [conversationId, setConversationId] = useState<string>(value?.conversationId || '');

  useEffect(() => {
    if (value.patientId) {
      setPatientId(value.patientId);
    }
    if (value.patientName) {
      setPatientName(value.patientName);
    }
  }, [value]);

  portalActions = {
    resetParams: () => {
      setPatientId('');
      setIsNewPatient(false);
      setOrderId('');
      setLabCode('');
      setAnchor('');
      setConversationId('');
    },
    setIsNewPatient: () => {
      setIsNewPatient(true);
    },
  };

  return (
    <PortalContext.Provider
      value={{
        ...value,
        patientId: patientId,
        patientName: patientName,
        isNewPatient: isNewPatient,
        orderId: orderId,
        labCode: labCode,
        anchor: anchor,
        conversationId: conversationId,
        module: value?.module || '',
        hostingModuleUrl: value?.hostingModuleUrl || '',
        organizationId: value?.organizationId || null,
        organizationAddressId: value?.organizationAddressId || null,
        orderLinkTemplate: value?.orderLinkTemplate || null,
        resultLinkTemplate: value?.resultLinkTemplate || null,
      }}
    >
      <WithContext>{children}</WithContext>
    </PortalContext.Provider>
  );
};
