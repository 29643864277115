import { TChatItem } from 'src/api/chats/models';
import { ActionTypesChats, initialStateChats, StateChats } from './const';
import { ChatType } from 'src/api/common/models';

type Action = {
  type: ActionTypesChats;
  payload: any;
};
const uniq = (arr: TChatItem[], track = new Set()) => arr.filter(({ id }) => (track.has(id) ? false : track.add(id)));

export function reducer(state: StateChats, action: Action): StateChats {
  const { type, payload } = action;
  switch (type) {
    case ActionTypesChats.SET_FETCHING: {
      return { ...state, isFetching: payload as boolean };
    }
    case ActionTypesChats.SET_END_CHATS: {
      return { ...state, isEndOfAllChats: payload as boolean };
    }
    case ActionTypesChats.SET_END_SEARCH_CHATS: {
      return { ...state, isEndOfAllSearchChats: payload as boolean };
    }
    case ActionTypesChats.SET_FIRST_LOADING: {
      return { ...state, firstLoading: payload as boolean };
    }
    case ActionTypesChats.SET_CHATS: {
      return { ...state, chats: payload as TChatItem[] };
    }
    case ActionTypesChats.ADD_CHATS_BOTTOM: {
      return { ...state, chats: uniq([...state.chats, ...(payload as TChatItem[])]) };
    }
    case ActionTypesChats.SET_ACTIVE_CHAT: {
      return { ...state, activeChat: payload as string };
    }
    case ActionTypesChats.SET_CREATED_ACTIVE_CHAT: {
      return { ...state, createdActiveChat: payload as string };
    }
    case ActionTypesChats.SET_ACTIVE_CHAT_LABCODE: {
      return { ...state, activeChatLabCode: payload ? (payload as string) : null };
    }
    case ActionTypesChats.SET_SEARCH_CHAT_INITIAL: {
      return { ...state, searchChats: payload as TChatItem[] };
    }
    case ActionTypesChats.ADD_SEARCH_CHAT_BOTTOM: {
      return { ...state, searchChats: [...state.searchChats, ...(payload as TChatItem[])] };
    }
    case ActionTypesChats.SET_UNREAD_ONLY: {
      return { ...state, unreadOnly: payload as boolean, activeChat: initialStateChats.activeChat };
    }
    case ActionTypesChats.SET_CHAT_TYPE: {
      return { ...state, typeActiveChat: payload as ChatType };
    }
    case ActionTypesChats.SET_ID_ANCHOR_MESSAGE: {
      return payload
        ? {
            ...state,
            searchedMessage: payload as string,
          }
        : {
            ...state,
            searchedMessage: state.searchChats.find(item => item.lastMessage.created === state.createdActiveChat)
              ?.lastMessage.created as string,
          };
    }
    case ActionTypesChats.CHANGE_CHATS_WITH_NEW_MESSAGE: {
      const indexCurrentChat = state.chats.findIndex(chat => chat.id === payload.conversationId);
      const currentChat = state.chats[indexCurrentChat];
      return {
        ...state,
        chats: [
          {
            ...currentChat,
            unreadCount: payload.fromCurrentModule ? currentChat.unreadCount : currentChat.unreadCount + 1,
            lastMessage: { ...payload, isRead: false, created: payload.created },
            updated: payload.created,
          },
          ...state.chats.filter((_, index) => indexCurrentChat !== index),
        ],
      };
    }
    case ActionTypesChats.CHANGE_READ_LAST_MESSAGE: {
      return {
        ...state,
        chats: state.chats.map(chat =>
          chat.id === payload.message.conversationId
            ? {
                ...chat,
                unreadCount: chat.unreadCount - payload.countReadMessage,
                lastMessage: { ...chat.lastMessage, isRead: true },
              }
            : chat,
        ),
      };
    }
    case ActionTypesChats.CHANGE_STATUS_LAST_TASK: {
      return {
        ...state,
        chats: state.chats.map(chat =>
          chat.id === payload.conversationId
            ? {
                ...chat,
                lastMessage: { ...chat.lastMessage, status: payload.status },
              }
            : chat,
        ),
      };
    }
    case ActionTypesChats.ADD_CHAT_WITH_PATIENT: {
      return {
        ...state,
        chats: [
          {
            id: payload.message.conversationId,
            name: payload.message.fromCurrentModule ? payload.patientName : payload.message.author,
            lastMessage: payload.message,
            unreadCount: payload.message.fromCurrentModule ? 0 : 1,
            updated: payload.message.updated,
            patientId: payload.patientId,
            conversationType: ChatType.DoctorPatient,
          },
          ...state.chats,
        ],
      };
    }
    case ActionTypesChats.ADD_CHAT_TO_TOP: {
      const newChats = [...state.chats].slice(0, -1);
      return {
        ...state,
        chats: [payload, ...newChats],
        activeChat: payload.id,
        createdActiveChat: payload.lastMessage?.created,
      };
    }
    default: {
      throw new Error('Error chat action');
    }
  }
}
