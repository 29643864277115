import { IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { border, borderBox } from 'src/const';
import { searchValueActions } from 'src/context/searchValue';
import { ReactComponent as SearchIcon } from 'src/icons/search.svg';
import { ReactComponent as ClearIcon } from 'src/icons/close-sm.svg';
import useDebounce from 'src/hooks/useDebounce';
import { messagesActions } from 'src/context/messages';
import { chatsActions } from 'src/context/chats';

const classes = {
  box_wrapper: {
    borderBottom: border,
    boxSizing: borderBox,
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 12px 0px 12px',
    height: '92px',
  } as SxProps,
};

const styles = {
  width: '406px',
  '& .MuiInputBase-root': {
    gap: '0',
    height: '42px',
  },
};

type SearchBlockConversationProps = {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

export const SearchBlockConversation: React.FC<SearchBlockConversationProps> = ({ searchValue, setSearchValue }) => {
  const debouncedValue = useDebounce<string>(searchValue, 300);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const handleClearClick = () => {
    setSearchValue('');
  };

  useEffect(() => {
    searchValueActions?.setSearchValueConversation(debouncedValue);
  }, [debouncedValue]);
  return (
    <Stack
      sx={{
        ...classes.box_wrapper,
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search"
        sx={styles}
        value={searchValue}
        onChange={handleChange}
        onClick={() => {
          messagesActions.setIsViewFoundMessagesConversation(true);
          chatsActions.setAnchorMessage('');
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                margin: '0 8px 0 0',
              }}
            >
              <Stack direction="row" gap="4px" flexWrap="nowrap" alignItems="center">
                <SearchIcon />
              </Stack>
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{ visibility: searchValue ? 'visible' : 'hidden', padding: '0', height: '100%' }}
              onClick={handleClearClick}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};
