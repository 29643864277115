import React from 'react';
import { PulseLoader } from 'react-spinners';
import { Box, Stack } from '@mui/system';

interface IProps {
  size?: number;
  zIndex?: number;
  [key: string]: any;
}

export type IPreloaderBubbles = React.FunctionComponent<IProps>;

export const PreloaderBubbles: IPreloaderBubbles = ({ size = 10, position, zIndex = 5 }) => {
  return (
    <Box
      sx={{
        position: position || 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: position === 'sticky' ? '100%' : 'auto',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        height="100%"
        sx={{
          '@keyframes react-spinners-PulseLoader-pulse': {
            '0%': {
              transform: 'scale(1)',
              opacity: 1,
            },
            '45%': {
              transform: 'scale(0.1)',
              opacity: 0.7,
            },
            '80%': {
              transform: 'scale(1)',
              opacity: 1,
            },
          },
        }}
      >
        <PulseLoader color="#0096b2" size={size} />
      </Stack>
    </Box>
  );
};
