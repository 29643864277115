import { TMessageItem } from 'src/api/messages/models';
import { ActionTypesMessages, StateMessages } from './const';

type Action = {
  type: ActionTypesMessages;
  payload: any;
};

export function reducer(state: StateMessages, action: Action): StateMessages {
  const { type, payload } = action;
  switch (type) {
    case ActionTypesMessages.SET_FETCHING: {
      return { ...state, isFetching: payload as boolean };
    }
    case ActionTypesMessages.GET_CHAT_MESSAGE: {
      return payload.firstMessage
        ? {
            ...state,
            messages: [...(payload.messages as TMessageItem[]), payload.firstMessage as TMessageItem],
          }
        : {
            ...state,
            messages: [],
          };
    }
    case ActionTypesMessages.SET_LENGTH_MESSAGE_SEARCH: {
      return { ...state, lengthMessageSearch: payload as number };
    }
    case ActionTypesMessages.SET_END_BOTTOM_MESSAGES: {
      return { ...state, isEndBottomMessage: payload as boolean };
    }
    case ActionTypesMessages.ADD_SEARCH_MESSAGES_BOTTOM: {
      return { ...state, searchMessages: [...state.searchMessages, ...(payload as TMessageItem[])] };
    }
    case ActionTypesMessages.SET_END_SEARCH_MESSAGES: {
      return { ...state, isEndOfAllSearchMessages: payload as boolean };
    }
    case ActionTypesMessages.SET_SEARCH_MESSAGES_INITIAL: {
      return { ...state, searchMessages: payload as TMessageItem[] };
    }
    case ActionTypesMessages.ADD_SEARCH_MESSAGES_CONVERSATION_BOTTOM: {
      return {
        ...state,
        searchMessagesConversation: [...state.searchMessagesConversation, ...(payload as TMessageItem[])],
      };
    }
    case ActionTypesMessages.SET_END_SEARCH_MESSAGES_CONVERSATION: {
      return { ...state, isEndOfAllSearchMessagesConversation: payload as boolean };
    }
    case ActionTypesMessages.SET_SEARCH_MESSAGES_CONVERSATION_INITIAL: {
      return { ...state, searchMessagesConversation: payload as TMessageItem[] };
    }
    case ActionTypesMessages.SET_IS_VIEW_FOUND_MESSAGES_CONVERSATION: {
      return { ...state, isViewFoundMessagesConversation: payload as boolean };
    }
    case ActionTypesMessages.SET_END_TOP_MESSAGES: {
      return { ...state, isEndTopMessage: payload as boolean };
    }
    case ActionTypesMessages.SET_LOADING_FOR_PLELOADER: {
      return { ...state, firstLoading: payload as boolean };
    }
    case ActionTypesMessages.SET_IS_SCROLL_TOP: {
      return { ...state, isScrollTop: payload as boolean };
    }
    case ActionTypesMessages.SET_MESSAGES: {
      return { ...state, messages: payload as TMessageItem[] };
    }
    case ActionTypesMessages.ADD_MESSAGES_BOTTOM: {
      return { ...state, messages: [...state.messages, ...(payload as TMessageItem[])] };
    }
    case ActionTypesMessages.ADD_MESSAGES_TOP: {
      return { ...state, messages: [...(payload as TMessageItem[]), ...state.messages] };
    }

    case ActionTypesMessages.SET_IS_SENT_MESSAGE: {
      return { ...state, isSentMessage: payload as boolean };
    }
    case ActionTypesMessages.GET_MESSAGE_QUEUE: {
      return {
        ...state,
        messages:
          payload.activeChat === payload.message.conversationId
            ? [...state.messages, payload.message as TMessageItem]
            : state.messages,
      };
    }
    case ActionTypesMessages.READ_MESSAGES: {
      return {
        ...state,
        messages: state.messages.map(message => (message.id === payload ? { ...message, isRead: true } : message)),
      };
    }
    case ActionTypesMessages.CHANGE_TASK_STATUS: {
      return {
        ...state,
        messages: state.messages.map(message =>
          message.assignmentId === payload.assignmentId
            ? { ...message, status: payload.status, actor: payload.actor }
            : message,
        ),
      };
    }
    default: {
      throw new Error('Error message action');
    }
  }
}
