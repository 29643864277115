import { useContext } from 'react';
import { FormControlLabel, Box, SxProps, Switch } from '@mui/material';
import { border, borderBox } from 'src/const';
import { chatsActions, ChatsContext } from 'src/context/chats';
import { SearchValueContext } from 'src/context/searchValue';

const classes = {
  box_wrapper: {
    borderBottom: border,
    height: '52px',
    paddingRight: '24px',
    boxSizing: borderBox,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'end',
    flexShrink: 0,
  } as SxProps,
};

export const UnreadSwitchBlock = () => {
  const { unreadOnly } = useContext(ChatsContext);
  const { searchValue } = useContext(SearchValueContext);
  const handleChange = () => {
    chatsActions.setUnreadOnly(!unreadOnly);
  };

  return !searchValue ? (
    <Box sx={classes.box_wrapper}>
      <FormControlLabel
        control={
          <Switch
            checked={unreadOnly}
            disableRipple
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Unread only"
        labelPlacement="start"
      />
    </Box>
  ) : null;
};
