import React, { createContext } from 'react';
import { useReducer } from 'react';
import { reducer } from './reducer';
import { initialStateMessages, StateMessages } from './const';

type PropsProvider = {
  children: JSX.Element;
};

export const MessagesContext = createContext<StateMessages>(initialStateMessages);

export let dispatchMessages: React.Dispatch<any> = null as unknown as React.Dispatch<any>;

export const MessagesProvider: React.FC<PropsProvider> = ({ children }) => {
  const [value, dispatch] = useReducer(reducer, initialStateMessages);

  dispatchMessages = dispatch;

  return <MessagesContext.Provider value={value}>{children}</MessagesContext.Provider>;
};
