import { Typography, DialogTitle, styled as styledMui, MenuItem, DialogActions } from '@mui/material';
import { border } from 'src/const';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils';

const TitleModal = styledMui(Typography)`
font-weight: 700;
line-height: 22px;
color: ${theme.palette.grey[700]};
`;

const DialogTitleStyled = styledMui(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  borderBottom: border,
  position: 'relative',
  '&+.MuiDialogContent-root': {
    padding: '12px 24px!important',
  },
}));

const MenuItemStyled = styledMui(MenuItem)(() => ({
  height: '40px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.grey['700'],
  '&.Mui-selected': {
    background: hexOpacity(theme.palette.secondary.main, '14%'),
    color: theme.palette.secondary.main,
    '&:hover': {
      background: hexOpacity(theme.palette.secondary.main, '14%'),
    },
  },
  '&:hover': {
    background: hexOpacity(theme.palette.secondary.main, '7%'),
    color: theme.palette.secondary.main,
  },
}));

const selectStyle = {
  '& .MuiSelect-select .notranslate::after': 'placeholder'
    ? {
        content: `"${'Select'}"`,
        opacity: 0.42,
      }
    : {},
};

const DialogActionsStyle = styledMui(DialogActions)`
justify-content: space-between;
margin: 24px;
`;

export { TitleModal, DialogTitleStyled, MenuItemStyled, selectStyle, DialogActionsStyle };
