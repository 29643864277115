import { createContext } from 'react';

export type TPortalContextValue = {
  patientName?: Nullable<string>;
  patientId?: Nullable<string>;
  isNewPatient?: boolean;
  isUnmount?: Nullable<string>;
  orderId?: Nullable<string>;
  labCode?: Nullable<string>;
  module?: Nullable<string>;
  hostingModuleUrl?: Nullable<string>;
  organizationId?: Nullable<string>;
  organizationAddressId?: Nullable<string>;
  orderLinkTemplate?: Nullable<string>;
  resultLinkTemplate?: Nullable<string>;
  conversationId?: Nullable<string>;
  anchor?: Nullable<string>;
};
export const PortalContext = createContext<TPortalContextValue>({
  patientName: '',
  patientId: '',
  isNewPatient: false,
  isUnmount: '',
  orderId: '',
  labCode: '',
  module: '',
  hostingModuleUrl: '',
  organizationId: null,
  organizationAddressId: null,
  orderLinkTemplate: null,
  resultLinkTemplate: null,
  conversationId: '',
  anchor: '',
});
