import axios from 'axios';

//const mdlKey = 'mdl';
//const mdlCookie = document.cookie.match(new RegExp('(^| )' + mdlKey + '=([^;]+)'));

const axiosClient = axios.create({
  baseURL: `${window.env?.REACT_APP_MESSENGER_BASE_URL ?? process.env.REACT_APP_MESSENGER_BASE_URL}/api/v1`,
  withCredentials: true,
});

export default axiosClient;
