import React, { useState } from 'react';
import { getPermissions } from 'src/api/profile/profile';
import { ProfileContext } from './context';

type PropsProvider = {
  children: JSX.Element;
};

type TProfileAction = null | {
  getProfilePermissions: () => void;
};

export let profileActions: TProfileAction = null;

export const ProfileProvider: React.FC<PropsProvider> = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  profileActions = {
    getProfilePermissions: async () => {
      const permissionsList = await getPermissions();
      setPermissions(permissionsList.data);
    },
  };

  return (
    <ProfileContext.Provider
      value={{
        permissions: permissions,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
