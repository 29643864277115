import { TSendTaskMakeAnAppointment, TSendTaskAddOnTestRequest, TNewStatus } from './models';
import API from '../api';
import { TMessageItem } from '../messages/models';

const PATH_ASSIGNEMEVTS = '/Assignments';

const sendAssignmentMakeAnAppointment = (task: TSendTaskMakeAnAppointment) =>
  API.post<TMessageItem>(`${PATH_ASSIGNEMEVTS}/make-an-appointment`, task).then(result => result);

const sendAssignmentAddOnTestRequest = (task: TSendTaskAddOnTestRequest) =>
  API.post<TMessageItem>(`${PATH_ASSIGNEMEVTS}/add-on-tests-request`, task).then(result => result);

const updateStatus = (newStatus: TNewStatus) => API.patch(`${PATH_ASSIGNEMEVTS}/update-status`, newStatus);

export { sendAssignmentMakeAnAppointment, sendAssignmentAddOnTestRequest, updateStatus };
