import {
  Autocomplete,
  AutocompleteProps,
  Box,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  ListItem,
  Stack,
  SxProps,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Label } from 'src/components/ui';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as IconArrow } from 'src/icons/chevron-down.svg';
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';

type SelectProps<
  T = ISelectOption<string>,
  Multiple extends boolean | undefined = undefined | boolean,
  DisableClearable extends boolean | undefined = undefined | boolean,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'PaperComponent'> & {
  label: string;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  options: ISelectOption<string>[];
  helperText?: string;
  limitTags?: number;
  variant?: 'outlined' | 'filled';
  onSearch?: any;
  setState?: any;
  withoutArrow?: boolean;
  disableClearable?: boolean;
  loading?: boolean;
  PaperComponent?: any;
  sxControl?: SxProps;
  name: string;
  control: any;
  required?: boolean;
};

export const classes = {
  input: {
    width: 352,
  },
};

export const FormContolAutocomplete = ({
  label,
  multiple,
  placeholder,
  options,
  onChange,
  // value,
  helperText,
  onSearch,
  setState,
  disabled,
  variant = 'outlined',
  withoutArrow = false,
  disableClearable = true,
  loading,
  sxControl,
  onClick,
  name,
  control,
  required,
  ...rest
}: SelectProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  useEffect(() => {
    if (onSearch && debouncedValue) {
      onSearch({ ...setState, search: debouncedValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange: onChangeField, value } }) => {
        return (
          <FormControlLabel
            label={<Label label={label} />}
            labelPlacement="top"
            sx={{ width: '100%', cursor: 'default', ...sxControl }}
            disabled={disabled}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            control={
              <Stack width="100%">
                <Autocomplete
                  sx={{ minWidth: '352px' }}
                  multiple={multiple}
                  disablePortal
                  fullWidth
                  disabled={disabled}
                  value={value}
                  // value={typeof value === 'string' ? { label: value, value } : value}
                  options={options}
                  loading={loading}
                  disableClearable={!value || disableClearable}
                  getOptionLabel={option => option.label || ''}
                  popupIcon={!withoutArrow ? <IconArrow /> : null}
                  clearIcon={<CrossIcon />}
                  placeholder="Select"
                  renderTags={() => ''}
                  onChange={(event, val, ...all) => {
                    if (onChange) {
                      onChange(event, val, ...all);
                    } else onChangeField(Object.assign(event, { target: { value: val } }), ...all);
                  }}
                  // onChange={(_, data) => onChange && onChange(data, null, null)}
                  onClose={() => {
                    onSearch && onSearch({ ...setState });
                    setSearchValue('');
                  }}
                  {...rest}
                  renderOption={(props, option) => {
                    const key = typeof option.value === 'string' ? option.value : option.key;
                    return (
                      <ListItem {...props} key={key} onClick={props.onClick}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="100%"
                          sx={{ paddingTop: '5px', paddingBottom: '5px' }}
                        >
                          <Box>{option?.label}</Box>
                        </Stack>
                      </ListItem>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      hiddenLabel
                      placeholder={placeholder}
                      variant={variant}
                      color="secondary"
                      onClick={onClick}
                      onChange={handleChange}
                      InputLabelProps={{
                        style: { fontSize: '14px' },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <Stack alignItems="center" paddingRight="4px">
                                <CircularProgress color="secondary" size={20} />
                              </Stack>
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        style: {
                          flexWrap: 'nowrap',
                          overflowX: 'hidden',
                        },
                      }}
                    />
                  )}
                />
                {helperText && <FormHelperText sx={{ color: '#d32f2f' }}>{helperText}</FormHelperText>}
              </Stack>
            }
          />
        );
      }}
    />
  );
};
