import { useState, useEffect } from 'react';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../const';
interface Size {
  isMobileSize: boolean | undefined;
  isTabletSize: boolean | undefined;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    isMobileSize: false,
    isTabletSize: false,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        isMobileSize: window.innerWidth <= MAX_MOBILE_WIDTH,
        isTabletSize: window.innerWidth <= MAX_TABLET_WIDTH && window.innerWidth > MAX_MOBILE_WIDTH,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
