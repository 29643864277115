import { ListItem, styled } from '@mui/material';
import { border } from 'src/const';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils/hexOpacity';


const MessageItem = styled(ListItem)`
  padding: 0;
  cursor: pointer;
  border-left: 4px solid transparent;
  border-bottom: ${border};
  flex-shrink: 0;
  &:hover {
    background: ${theme.palette.grey[100]};
  }
  &.Mui-selected {
    background: ${hexOpacity(theme.palette.secondary.main, '7%')};
  }
`;

export { MessageItem };
