import React from 'react';
import { TMessageItem } from 'src/api/messages/models';
import { TaskMessage } from '../Task.styled';
import { Box } from '@mui/material';

type MakeAnAppointmentProps = {
  task: TMessageItem;
};

export const MakeAnAppointment: React.FC<MakeAnAppointmentProps> = ({ task }) => {
  const { body } = task;
  return body ? (
    <Box mb="4px">
      <TaskMessage inline={1} prewrap={1} fontWeight={500}>
        {'Comment: '}
      </TaskMessage>
      <TaskMessage inline={1} component="span">
        {body}
      </TaskMessage>
    </Box>
  ) : null;
};
