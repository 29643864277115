import { getChatByIdentifier, getChats, searchChats } from 'src/api/chats/chats';
import { dispatchChats } from './provider';
import { ActionTypesChats } from './const';
import axios from 'axios';
import { SearchType, TChatItem } from 'src/api/chats/models';
import { ContentTypeMessageQueue, TMessageItem } from 'src/api/messages/models';
import { portalActions } from '../portal';
import { ChatType, Pager } from 'src/api/common/models';

/**
 * @param flag флаг загрузки чатов, значение true указывает, что запрос в настоящее время выполняется
 */
const setFetching = (flag: boolean) => {
  dispatchChats({
    type: ActionTypesChats.SET_FETCHING,
    payload: flag,
  });
};

/**
 * @param flag флаг первой загрузки чатов, значение true указывает, что запрос в данный момент загружается в первый раз и еще не имеет данных
 */
const setFirstLoading = (flag: boolean) => {
  dispatchChats({
    type: ActionTypesChats.SET_FIRST_LOADING,
    payload: flag,
  });
};

/**
 * Инциализация массива искомых чатов
 */
export const resetSearchChat = (chats: TChatItem[]) => {
  dispatchChats({
    type: ActionTypesChats.SET_SEARCH_CHAT_INITIAL,
    payload: chats,
  });
};

/**
 * Установка активного чата
 * @param id id активного чата
 */
export const setActiveChat = (id: string) => {
  dispatchChats({
    type: ActionTypesChats.SET_ACTIVE_CHAT,
    payload: id,
  });
};

/**
 * Установка created активного чата (нужно для выделения активного чата при поиске, если чат с одним id выводится несколько раз)
 * @param created created активного чата
 */
export const setCreatedActiveChat = (created: string) => {
  dispatchChats({
    type: ActionTypesChats.SET_CREATED_ACTIVE_CHAT,
    payload: created,
  });
};

/**
 * Setting up selected chat laboratory code (id). Is used for addon-test-request form
 * @param labCode labCode активного чата
 */
export const setActiveChatLabCode = (labCode?: string) => {
  dispatchChats({
    type: ActionTypesChats.SET_ACTIVE_CHAT_LABCODE,
    payload: labCode,
  });
};

/**
 * Установка searchedMessage для загрузки сообщений чата
 * @param anchor created искомого сообщения
 */
export const setAnchorMessage = (anchor?: string) =>
  dispatchChats({
    type: ActionTypesChats.SET_ID_ANCHOR_MESSAGE,
    payload: anchor,
  });

/**
 * Сброс флагов о полной загрузке чатов
 */
export const resetLoadingAllChats = () => {
  dispatchChats({
    type: ActionTypesChats.SET_END_CHATS,
    payload: false,
  });
  dispatchChats({
    type: ActionTypesChats.SET_END_SEARCH_CHATS,
    payload: false,
  });
};

/**
 * Установка флага unreadOnly для загрузки чатов
 * @param flag флаг для загрузки чатов с unreadOnly
 */
export const setUnreadOnly = (flag: boolean) => {
  dispatchChats({
    type: ActionTypesChats.SET_UNREAD_ONLY,
    payload: flag,
  });
};

/**
 * Поиск чатов по searchedValue
 * @param message текст для поиска по чатам
 * @param value параметры для загрузки чата
 */
export const searchChat = async (message: string, value: Pager) => {
  try {
    setFetching(true);
    setFirstLoading(value.skip < 1);
    const chatsListSearch = await searchChats(message, value);
    if (chatsListSearch?.data.length) {
      if (value.skip > 0) {
        dispatchChats({
          type: ActionTypesChats.ADD_SEARCH_CHAT_BOTTOM,
          payload: chatsListSearch?.data,
        });
      } else resetSearchChat(chatsListSearch?.data);
    } else {
      dispatchChats({
        type: ActionTypesChats.SET_END_SEARCH_CHATS,
        payload: true,
      });
      if (value.skip < 1) {
        resetSearchChat([]);
      }
    }
    setFetching(false);
    setFirstLoading(false);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Получание списка чатов
 * @param value take и skip при загрузке чатов
 * @param unreadOnly флаг для загрузки прочитанных/непрочитанных чатов
 */
export const getChatsAsync = async (value: Pager, unreadOnly: boolean) => {
  setFirstLoading(true);
  const chatsList = await getChats(value, unreadOnly);
  const { data } = chatsList;
  if (dispatchChats) {
    dispatchChats({
      type: ActionTypesChats.SET_CHATS,
      payload: data.conversations,
    });
  }
  setFirstLoading(false);
};

/**
 * Загрузка чатов при скролле
 * @param value take и skip при загрузке чатов
 * @param unreadOnly флаг для загрузки прочитанных/непрочитанных чатов
 */
export const scrollChat = async (value: Pager, unreadOnly: boolean) => {
  try {
    setFetching(true);
    const chatsList = await getChats(value, unreadOnly);
    if (chatsList?.data.conversations.length && dispatchChats) {
      dispatchChats({
        type: ActionTypesChats.ADD_CHATS_BOTTOM,
        payload: chatsList.data.conversations,
      });
    } else {
      dispatchChats({
        type: ActionTypesChats.SET_END_CHATS,
        payload: true,
      });
    }
    setFetching(false);
  } catch (error_) {
    if (axios.isAxiosError(error_)) {
      // setError(error_);
    }
  }
};

/**
 * Изменение списка чатов при получении сообщений по очереди
 * @param message body входящего сообщдения
 * @param contentType тип входящего сообщения (новое сообщение, прочтение сообщений, изменение статуса задачи)
 * @param countReadMessage количество прочитанных сообщений
 */
export const changeChatList = (message: TMessageItem, contentType: string, countReadMessage?: number) => {
  if (contentType === ContentTypeMessageQueue.NewMessage) {
    dispatchChats({
      type: ActionTypesChats.CHANGE_CHATS_WITH_NEW_MESSAGE,
      payload: message,
    });
  }
  if (contentType === ContentTypeMessageQueue.ReadMessages) {
    dispatchChats({
      type: ActionTypesChats.CHANGE_READ_LAST_MESSAGE,
      payload: { message, countReadMessage },
    });
  }
  if (contentType === ContentTypeMessageQueue.AssignmentStatusChanged) {
    dispatchChats({
      type: ActionTypesChats.CHANGE_STATUS_LAST_TASK,
      payload: message,
    });
  }
};

/**
 * Добавление чата с пациентом, если ранее чата с ним не было
 * @param message текст сообщения
 * @param patientName имя пациента
 * @param patientId id пациента
 */
export const addChatWitnPatient = (
  message: TMessageItem,
  patientName?: Nullable<string>,
  patientId?: Nullable<string>,
) => {
  dispatchChats({
    type: ActionTypesChats.ADD_CHAT_WITH_PATIENT,
    payload: { message, patientName, patientId },
  });
};

/**
 * Добавление нужного чата в топ списка и установка его активным при переходе в мессенджер по идентификаторам
 * @param id id чата
 * @param searchType тип поиска (по id чата, по labcode или patientId)
 */
export const addChatToTop = async (id: string, searchType: SearchType) => {
  const chatWithIdentifier = await getChatByIdentifier(id, searchType);
  const { data: chat, status } = chatWithIdentifier;
  if (
    (searchType === SearchType.ByPatientId && status === 200 && chat != null) ||
    (searchType !== SearchType.ByPatientId && status !== 404)
  ) {
    dispatchChats({
      type: ActionTypesChats.ADD_CHAT_TO_TOP,
      payload: chat,
    });
  }
  if (status === 200 && chat == null && searchType === SearchType.ByPatientId) {
    portalActions?.setIsNewPatient();
  }
};

/**
 * Установка типа чата
 * @param typeActiveChat тип чата
 */
export const setChatType = (typeActiveChat: ChatType) => {
  dispatchChats({
    type: ActionTypesChats.SET_CHAT_TYPE,
    payload: typeActiveChat,
  });
};
