import React, { useContext } from 'react';
import { TMessageItem } from '../api/messages/models';
import { MessagesContext } from '../context/messages';

export function useChatScroll(
  dep: TMessageItem[],
  lastItem: any,
  searchValue: any,
  isBottom: boolean,
): React.MutableRefObject<HTMLDivElement> {
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const { isScrollTop, isSentMessage } = useContext(MessagesContext);
  React.useEffect(() => {
    if (!(searchValue && !isBottom) && lastItem.current && (!isScrollTop || isSentMessage)) {
      lastItem.current.scrollIntoView({ block: 'end' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep, isSentMessage]);
  return ref;
}
