import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { MessageItem } from './styled';
import { ChatsContext, chatsActions } from 'src/context/chats';
import { Stack, SxProps } from '@mui/system';
import { formatUTCDate } from 'src/utils/dateUtils';
import Avatar from '../Dialog/Header/Avatar';
import { TMessageItem } from 'src/api/messages/models';
import { searchValueActions } from 'src/context/searchValue';

const classes = {
  box_wrapper: {
    width: '100%',
    padding: '12px 12px 12px 20px',
  } as SxProps,
};

type MessageListItemProps = {
  message: TMessageItem;
};

export const MessageListItem = React.forwardRef<HTMLDivElement, MessageListItemProps>(({ message }, ref) => {
  const { activeChat, searchedMessage } = useContext(ChatsContext);
  const { id, name, unreadCount, laboratoryCode, conversationAvatarUrl } = message?.conversation || {
    id: '',
    name: '',
    unreadCount: 0,
    updated: '',
    laboratoryCode: '',
  };
  const { created, body, originalOrderNumber } = message;
  const date = formatUTCDate(created);
  const handleClick = () => {
    chatsActions?.setActiveChat(id);
    chatsActions.setAnchorMessage(created);
    chatsActions?.setCreatedActiveChat(created);
    chatsActions?.setActiveChatLabCode(laboratoryCode);
    searchValueActions?.setSearchValueConversation('');
  };

  const formattedBody = body.replace('__order__', originalOrderNumber || '').replace('**__report__**', 'report');

  return (
    <MessageItem
      selected={id === activeChat && searchedMessage === created}
      onClick={handleClick}
      children={
        <Stack direction="row" sx={classes.box_wrapper} ref={ref} gap="12px">
          <Avatar name={name || ''} src={conversationAvatarUrl} />
          <Stack width="calc(100% - 145px)">
            <Typography variant="body18Bold" color={unreadCount > 0 ? 'grey.800' : 'grey.600'} noWrap lineHeight="24px">
              {name}
            </Typography>
            <Stack justifyContent="space-between" direction="row">
              <Typography
                variant="label14Medium"
                color={unreadCount > 0 ? 'grey.700' : 'grey.500'}
                lineHeight="20px"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {formattedBody}
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="space-between" alignItems="end">
            <Typography noWrap variant="label14Medium" color="grey.400" lineHeight="20px" sx={{ flexShrink: 0 }}>
              {date}
            </Typography>
          </Stack>
        </Stack>
      }
    />
  );
});
