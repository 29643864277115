import { ChatType } from '../common/models';
import { TMessageItem } from '../messages/models';

export type TChatList = {
  conversations: TChatItem[];
  unreadCount: number;
};

export type TChatItem = {
  id: string;
  name: string;
  lastMessage: TMessageItem;
  unreadCount: number;
  updated: string;
  patientId?: string;
  conversationType: ChatType;
  laboratoryCode?: string;
  conversationAvatarUrl?: string;
};

export enum SearchType {
  ByLabcode = 'ByLabcode',
  ByPatientId = 'ByPatientId',
  ByConversationId = 'ByConversationId',
}
