import { DialogTitle, styled as styledMui, DialogActions, TextField, Chip, MenuItem } from '@mui/material';
import { border } from 'src/const';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils/hexOpacity';

const DialogTitleStyled = styledMui(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px',
  borderBottom: border,
  position: 'relative',
  '&+.MuiDialogContent-root': {
    padding: '36px 24px 48px 24px !important',
  },
}));

const TextFieldStyled = styledMui(TextField)(() => ({
  '&::placeholder': { fontSize: '14px' },
}));

const ChipTasks = styledMui(Chip)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  minWidth: '350px',
  maxWidth: '350px',
}));

const DialogActionsStyle = styledMui(DialogActions)`
padding: 24px;
justify-content: space-between;
border-top: 1px solid ${hexOpacity(theme.palette.secondary.main, '14%')}
`;

const SendMessageStyled = styledMui(TextField)(() => ({
  root: {
    border: border,
    height: '100%',
  },
  '& .MuiInputBase-root': {
    height: 96,
    padding: '12px',
  },
  textarea: {
    height: '100% !important',
  },
}));

const MenuItemOptions = styledMui(MenuItem)(() => ({
  height: '40px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  color: theme.palette.grey['700'],
  '&.Mui-selected': {
    background: hexOpacity(theme.palette.secondary.main, '14%'),
    color: theme.palette.secondary.main,
    '&:hover': {
      background: hexOpacity(theme.palette.secondary.main, '14%'),
    },
  },
  '&:hover': {
    background: hexOpacity(theme.palette.secondary.main, '7%'),
    color: theme.palette.secondary.main,
  },
}));

export { DialogTitleStyled, DialogActionsStyle, TextFieldStyled, ChipTasks, SendMessageStyled, MenuItemOptions };
