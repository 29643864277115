import { CategoriesDiagnoses } from 'src/api/portal/models';
import {
  getSelectableDiagnoses,
  getUnselectableDiagnoses,
  getRecentDiagnoses,
  getFavoritesDiagnoses,
  getСommonDiagnoses,
} from 'src/api/portal/portal';

export interface DiagnosesLocal {
  esbId: string;
  code: string;
  name: string;
}

type DiagnosesFetchProps = {
  setDiagnoses: React.Dispatch<React.SetStateAction<DiagnosesLocal[]>>;
  activeCategory: CategoriesDiagnoses;
  generalFilter: { search: string; page: { number: number; size: number } };
  patientId: string;
  orderId: string;
  setLoading: (flag: boolean) => void;
  scroll?: boolean;
  setCategoryDiagnoses: React.Dispatch<React.SetStateAction<DiagnosesLocal[]>>;
};
export const diagnosesFetch = ({
  activeCategory,
  generalFilter,
  patientId,
  orderId,
  setDiagnoses,
  setLoading,
  scroll,
  setCategoryDiagnoses,
}: DiagnosesFetchProps) => {
  const getFunction = () => {
    switch (activeCategory) {
      case CategoriesDiagnoses.Common: {
        return getСommonDiagnoses({ ...generalFilter, orderId });
      }
      case CategoriesDiagnoses.Favorites: {
        return getFavoritesDiagnoses(generalFilter);
      }
      case CategoriesDiagnoses.Recent: {
        return getRecentDiagnoses({ ...generalFilter, patientId });
      }
      case CategoriesDiagnoses.All: {
        return getSelectableDiagnoses(generalFilter);
      }
      default: {
        return getSelectableDiagnoses(generalFilter);
      }
    }
  };
  setLoading(true);
  if (activeCategory === CategoriesDiagnoses.All && !scroll) {
    getUnselectableDiagnoses(generalFilter)
      .then(result => {
        const newDiagnoses = result.map(({ code, name, esbId }) => ({
          name,
          code,
          esbId,
        }));
        return setCategoryDiagnoses(prev => (scroll ? [...prev, ...newDiagnoses] : newDiagnoses));
      })
      .catch(() => {});
  }
  void getFunction().then(result => {
    const newDiagnoses = result.map(({ code, name, esbId }) => ({
      name,
      code,
      esbId,
    }));
    setDiagnoses(prev => (scroll ? [...prev, ...newDiagnoses] : newDiagnoses));
    return setLoading(false);
  });
};
