const HEX_OPACITY = {
  '100%': 'FF',
  '90%': 'E6',
  '80%': 'CC',
  '70%': 'B3',
  '60%': '99',
  '50%': '80',
  '40%': '66',
  '30%': '4D',
  '21%': '36',
  '20%': '33',
  '10%': '1A',
  '14%': '24',
  '7%': '12',
};

export function hexOpacity(color: string, opacity: keyof typeof HEX_OPACITY) {
  return color + HEX_OPACITY[opacity];
}
