import { styled as styledMui, Button, Avatar } from '@mui/material';
import { theme } from 'src/theme';
import { hexOpacity } from 'src/utils/hexOpacity';

const AvatarWrapper = styledMui(Avatar)`
  width: 40px;
  min-width: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexOpacity(theme.palette.secondary.main, '21%')};
  color: #ffffff;
`;

const ButtonCancel = styledMui(Button)`
width: 110px;
height: 42px;
display: flex;
justify-content: center;
align-items: center;
`;

const ButtonSend = styledMui(Button)`
width: 110px;
height: 42px;
display: flex;
justify-content: center;
align-items: center;
color: #FFFFFF;
`;

export { AvatarWrapper, ButtonCancel, ButtonSend };
